/* eslint-disable camelcase */
import React, { useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { AppContext, DovetailContext, UserContext } from '../../context';
import DovetailComponent, {
	getDovetailLocale,
} from '../../components/common/DovetailComponent';
import { color, spacingPx } from '@planview/pv-utilities';
import styled from 'styled-components';
import messages from './NotificationsPage.messages';
import './NotificationPage.css';

const ErrorContainer = styled.div`
	padding: ${spacingPx.small} 24px;
`;

const PageContainer = styled.div`
	background-color: ${color.gray100};
	flex: 1;
	width: 100%;
	height: 100%;
`;

const DovetailContainer = styled.div`
	height: 100%;
`;

const NotificationsPage = () => {
	const lang = navigator.language;
	const userContext = useContext(UserContext);
	const { formatMessage } = useIntl();
	const { featureFlags, overviewApplications } = useContext(AppContext);
	const { dovetailEnvironment, dovetailUrl, notificationBackendUrl } =
		useContext(DovetailContext);
	const enableNotificationsModule = featureFlags.enableNotificationsModule;
	const userId = userContext.user.id;

	const { firstName, lastName, email } = userContext.user;
	const props = useMemo(
		() => ({
			environment: dovetailEnvironment,
			backendApiUrl: notificationBackendUrl.replace(/https?:\/\//, ''),
			user_id: userId,
			get_user_data: () =>
				Promise.resolve({
					userId,
					first_name: firstName,
					last_name: lastName,
					email_address: email,
					avatar: null,
				}),
			user_locale: {
				locale: getDovetailLocale(lang),
			},
		}),
		[
			dovetailEnvironment,
			userId,
			firstName,
			lastName,
			email,
			lang,
			notificationBackendUrl,
		],
	);

	if (!enableNotificationsModule) {
		return (
			<ErrorContainer>
				{formatMessage(messages.featureNotEnabled)}
			</ErrorContainer>
		);
	}

	const productionTenantGroupId = overviewApplications.find(
		(app) => !app.sandbox && app.tenantGroupId,
	)?.tenantGroupId;

	if (!productionTenantGroupId) {
		return (
			<ErrorContainer>
				{formatMessage(messages.noProductionApplication)}
			</ErrorContainer>
		);
	}

	return (
		<PageContainer>
			<DovetailComponent
				element={DovetailContainer}
				name="NotificationScreen"
				type="notifications"
				url={`${dovetailUrl}/dist/Notification.min.js`}
				tenantGroupId={productionTenantGroupId}
				props={props}
			/>
			;
		</PageContainer>
	);
};

export default NotificationsPage;
