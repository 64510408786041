import React, { useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import UsersPage from '../../admin/users/UsersPage';
import messages from './UsersPage.messages';
import { Tab, TabGroup } from '@planview/pv-uikit';
import { AppContext, AppContextProps } from '../../../context';
import { spacingPx } from '@planview/pv-utilities';
import { useNavigate, useParams } from 'react-router-dom';

export enum UserTabs {
	CURRENT_USERS,
	DEACTIVATED_USERS,
}

const StyledTabGroup = styled(TabGroup)`
	background-color: #ffffff;
`;

const TabBody = styled.div`
	flex-grow: 1;
	min-height: 0;
	padding-top: ${spacingPx.xsmall};
	display: flex;
	flex-direction: column;
`;

const tabMap: { [key: string]: UserTabs } = {
	current: UserTabs.CURRENT_USERS,
	deactivated: UserTabs.DEACTIVATED_USERS,
};

const loadTabs = (appContext: AppContextProps) => {
	const tabs = [UserTabs.CURRENT_USERS];
	if (appContext.featureFlags.enableDeactivateUsers) {
		tabs.push(UserTabs.DEACTIVATED_USERS);
	}
	return tabs;
};

type UsersTabPageParams = {
	tab: string;
};

const UsersTabsPage = () => {
	const { tab = '' } = useParams<UsersTabPageParams>();
	const tabIndex = tabMap[tab];
	const navigate = useNavigate();
	const appContext = useContext(AppContext);
	const intl = useIntl();
	const availableTabs = useMemo(() => loadTabs(appContext), [appContext]);
	const currentTab = availableTabs[tabIndex];

	const labels: { [tab: string]: () => string } = {
		[UserTabs.CURRENT_USERS]: () =>
			// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
			intl.formatMessage(messages.currentUsersTab),
		[UserTabs.DEACTIVATED_USERS]: () =>
			intl.formatMessage(messages.deactivatedUsersTab),
	};

	const changeTab = (newTab: UserTabs) => {
		const link = `/admin/users/${
			newTab === UserTabs.DEACTIVATED_USERS ? 'deactivated' : 'current'
		}`;
		navigate(link, { state: { redirect: true } });
	};

	return (
		<React.Fragment>
			{availableTabs.length > 1 && (
				<StyledTabGroup defaultIndex={tabIndex}>
					{availableTabs.map((tabType) => (
						<Tab
							key={tabType}
							onActivate={() => changeTab(tabType)}
							label={labels[tabType]()}
						/>
					))}
				</StyledTabGroup>
			)}
			{currentTab === UserTabs.CURRENT_USERS && (
				<TabBody>
					<UsersPage isCustomerCare={false} mode={currentTab} />
				</TabBody>
			)}
			{currentTab === UserTabs.DEACTIVATED_USERS && (
				<TabBody>
					<UsersPage isCustomerCare={false} mode={currentTab} />
				</TabBody>
			)}
		</React.Fragment>
	);
};

export default UsersTabsPage;
