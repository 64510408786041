import React, { useContext } from 'react';
import { FormattedDate, useIntl } from 'react-intl';
import styled from 'styled-components';
import Grid from '../common/grid/Grid';
import { AppContext, UserContext } from '../../context';
import messages from './UsersGrid.messages';
import './UsersGrid.css';
import { Checkmark, WarningFilled } from '@planview/pv-icons';
import { color } from '@planview/pv-utilities';
import { UserTabs } from '../../containers/admin/users/UsersTabsPage';
import {
	GridCellBase,
	GridCellDefault,
	useGridRow,
	useLocalStoragePreferences,
} from '@planview/pv-grid';
import GroupsContext from '../../context/groupsContext';
import { isAdaptiveWorkCustomer } from '../../helpers/util';

const LeftAlignedCheckIcon = styled(Checkmark)`
	width: 40px;
	justify-content: left;
`;

const LeftAlignedWarningFilledIcon = styled(WarningFilled)`
	width: 40px;
	float: left;
`;

const checkboxRenderer = ({ value, tabIndex }) => (
	<GridCellBase tabIndex={tabIndex}>
		{value ? <LeftAlignedCheckIcon /> : null}
	</GridCellBase>
);

const securityLevelLabel = ({ value }) => {
	// only for Super admins, no need to localize
	if (value.isSuperAdmin) {
		return 'Super admin';
	}
	if (value.isCustomerCare) {
		return 'Customer care';
	}
	if (value.isAdmin === false) {
		return 'User';
	}
};

const onlyUnique = (value, index, self) => {
	return self.indexOf(value) === index;
};

const applicationComparator = (a, b) => {
	if (a.sandbox && !b.sandbox) {
		return 1;
	}
	if (!a.sandbox && b.sandbox) {
		return -1;
	}

	let compare = a.productName.localeCompare(b.productName);
	if (compare === 0) {
		compare = a.title.localeCompare(b.title);
	}
	return compare;
};

const dateCellRenderer = ({ value }) =>
	value ? <FormattedDate value={new Date(value)} /> : '';

const getColumns = (
	intl,
	customer,
	userContext,
	groupsContext,
	applicationsById,
	mode,
	appContext,
	showPasswordColumn,
) => {
	showPasswordColumn ??= customer.ssoEnabled;
	const showGridForPVCustomer = userContext.isSuperAdminViewingPvCustomer(
		customer.id,
	);
	const { showLastLogin, enableCcSetUserGroups } = appContext.featureFlags;
	const showSystemGroups =
		userContext.isCustomerCare && enableCcSetUserGroups;
	const systemGroupsById = groupsContext?.systemGroupsById || new Map();

	const columns = [
		...(userContext.isCustomerCare
			? [
					{
						id: 'id',
						label: intl.formatMessage(messages.userIDColumn),
						sortable: false,
						width: 200,
					},
				]
			: []),
		{
			id: 'firstName',
			label: intl.formatMessage(messages.firstNameColumn),
			width: 200,
		},
		{
			id: 'lastName',
			label: intl.formatMessage(messages.lastNameColumn),
			width: 200,
		},
		{
			id: 'email',
			label: intl.formatMessage(messages.emailColumn),
			width: 200,
		},
		...(isAdaptiveWorkCustomer(customer)
			? [
					{
						id: 'username',
						sortable: true,
						label: intl.formatMessage(messages.usernameColumn),
						width: 200,
					},
				]
			: []),
	];

	if (
		mode === UserTabs.CURRENT_USERS &&
		customer.samlNameIdLookupType === 'LOGIN_ALIAS'
	) {
		columns.push({
			id: 'loginAlias',
			label: intl.formatMessage(messages.loginAliasColumn),
			width: 120,
		});
	}

	if (mode !== UserTabs.DEACTIVATED_USERS) {
		if (!showGridForPVCustomer) {
			columns.push({
				id: 'isAdmin',
				label: intl.formatMessage(messages.adminColumn),
				sortable: false,
				width: 100,
				cell: {
					Renderer: checkboxRenderer,
				},
			});
		} else {
			columns.push({
				id: 'securityLevel',
				label: 'Role',
				sortable: false,
				width: 100,
				cell: {
					value({ row }) {
						return row;
					},
					label: securityLevelLabel,
				},
			});
		}

		if (showPasswordColumn) {
			columns.push({
				id: 'authenticationType',
				label: intl.formatMessage(messages.localAuthColumn),
				sortable: false,
				width: 120,
				cell: {
					value({ row }) {
						return userContext.isCustomerCare
							? row.localAuthOverrideEnabled
							: row.authenticationType === 'PASSWORD';
					},
					Renderer: checkboxRenderer,
				},
			});
		}

		columns.push({
			id: 'envSelectors',
			label: intl.formatMessage(messages.productsColumn),
			sortable: false,
			width: 250,
			cell: {
				Renderer: ({ value, rowId, tabIndex }) => {
					const rowData = useGridRow(rowId);
					if (!Array.isArray(value)) {
						return '';
					}
					const sortedApps = value
						.map(
							(envSelector) =>
								applicationsById[
									`${envSelector.application}~${envSelector.tenantId}`
								],
						)
						.filter(Boolean) // remove nulls
						.sort(applicationComparator);
					const sandboxSuffix = intl.formatMessage(
						messages.sandboxSuffix,
					);
					const text = sortedApps
						.map((app) => {
							let result = app.productName;
							if (app.sandbox) {
								result = `${result} ${sandboxSuffix}`;
							}
							return result;
						})
						.filter(onlyUnique)
						.join(', ');

					const { provisionStatus } = rowData;

					return (
						<GridCellDefault
							tabIndex={tabIndex}
							icon={
								provisionStatus ? (
									<LeftAlignedWarningFilledIcon
										color={color.iconError}
									/>
								) : undefined
							}
							label={text}
						/>
					);
				},
			},
		});
	}

	if (mode === UserTabs.CURRENT_USERS) {
		columns.push({
			id: 'isActive',
			label: intl.formatMessage(messages.statusColumn),
			sortable: false,
			width: 80,
			cell: {
				value({ row }) {
					return row.isActive;
				},
				label: ({ value }) => {
					if (value === true) {
						return intl.formatMessage(messages.activeUser);
					} else if (value === false) {
						return intl.formatMessage(messages.pendingUser);
					}
					// do not display undefined as anything
					return '';
				},
			},
		});

		if (showLastLogin) {
			columns.push({
				id: 'lastLoginAt',
				label: intl.formatMessage(messages.lastLoginAtColumn),
				sortable: true,
				width: 80,
				cell: {
					label: (data) => dateCellRenderer(data),
				},
			});
		}
	} else if (mode === UserTabs.DEACTIVATED_USERS) {
		columns.push({
			id: 'deactivated',
			label: intl.formatMessage(messages.statusColumn),
			sortable: false,
			width: 120,
			cell: {
				label: () => intl.formatMessage(messages.deactivatedUser),
			},
		});
	}

	if (showSystemGroups) {
		columns.push({
			id: 'systemGroupIds',
			label: 'System groups',
			sortable: false,
			width: 200,
			cell: {
				value({ row }) {
					return row.systemGroupIds || [];
				},
				label: ({ value }) =>
					value
						?.map((groupId) => systemGroupsById.get(groupId)?.title)
						.join(', '),
			},
		});
	}

	return columns;
};

const UsersGrid = ({
	selectionType = 'checkbox',
	mode = UserTabs.CURRENT_USERS,
	...props
}) => {
	const intl = useIntl();
	const appContext = useContext(AppContext);
	const userContext = useContext(UserContext);
	const groupsContext = useContext(GroupsContext);
	const customer = props.customer || userContext.customer;
	const applicationsById =
		customer.tenantsByEnvSelector || appContext.applicationsById;
	const columns = React.useMemo(
		() =>
			getColumns(
				intl,
				customer,
				userContext,
				groupsContext,
				applicationsById,
				mode,
				appContext,
				props.showPasswordColumn,
			),
		[
			intl,
			customer,
			userContext,
			applicationsById,
			mode,
			appContext,
			groupsContext,
			props.showPasswordColumn,
		],
	);

	const preferencesKey = columns.map((c) => c.id).join('-');
	const preferencesAdapter = useLocalStoragePreferences(preferencesKey);

	let url = '/io/v1/user';
	// if the customer being viewed is different from current user's customer, use admin endpoint:
	if (customer.id !== userContext.customer.id) {
		url = `/io/v1/admin/user?customerId=${customer.id}`;
	}

	return (
		<Grid
			url={url}
			columns={columns}
			selectionType={selectionType}
			preferencesAdapter={preferencesAdapter}
			{...props}
		/>
	);
};

export default UsersGrid;
