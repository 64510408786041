import { IntlShape, useIntl } from 'react-intl';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
	CredentialsDto,
	CredentialsListResponse,
} from '../../../../types/api/tokens';
import {
	requestWithErrorHandling,
	get,
} from '../../../../hooks/request/request';
import { Column, Grid, useLocalStoragePreferences } from '@planview/pv-grid';
import { spacingPx } from '@planview/pv-utilities';
import { Filter, PlusCircle } from '@planview/pv-icons';
import { EmptyState, ListItem, Switch } from '@planview/pv-uikit';
import styled from 'styled-components';
import { AppContext, AppContextProps, UserContext } from '../../../../context';
import NewClientCredentialsModal from './NewClientCredentialsModal';
import { TenantGroup } from '../../../../types';
import {
	Toolbar,
	ToolbarButtonEmpty,
	ToolbarSection,
	ToolbarSeparator,
} from '@planview/pv-toolbar';
import messages from './ClientCredentials.messages';
import ToolbarActionsMenu, {
	ToolbarAction,
} from '../../actionsMenu/ToolbarActionsMenu';
import {
	ApplicationOption,
	ClientCredentialsData,
} from './ClientCredentialsTypes';
import ClientCredentialsFilter, {
	ClientCredentialsFilterParams,
	ClientCredentialsFilterParamsIndex,
	getEmptyClientCredentialsFilterParams,
	getClientCredentialsFilterFunction,
} from './ClientCredentialsFilter';
import {
	FilterContainer,
	MainContainer,
} from '../../../../components/common/Wrappers';
import { FilterBanner } from '@planview/pv-filter';
import DeactivateClientCredentialsModal from './DeactivateClientCredentialsModal';
import filterMessages from '../../../../components/common/filter/Filter.messages';
import { ToastType } from '../../../../types/toast';

const Container = styled.div`
	display: flex;
	flex-grow: 1;
	overflow: hidden;
	background-color: white;
	height: calc(100% - ${spacingPx.xlarge});
`;

const getApplicationOptions = (
	tenantGroupMap: Map<string, TenantGroup>,
	appContext: AppContextProps,
	intl: IntlShape,
): ApplicationOption[] => {
	const { enableLogbookReporting } = appContext.featureFlags;

	const options: ApplicationOption[] = [];
	for (const tenantGroup of tenantGroupMap.values()) {
		if (enableLogbookReporting) {
			options.push({
				label: intl.formatMessage(messages.logbookReportingLabel, {
					tenantGroupTitle: tenantGroup.title,
				}),
				application: 'LOGBOOK_REPORTING',
				tenantGroupId: tenantGroup.tenantGroupId,
			});
		}

		options.push({
			label: intl.formatMessage(messages.okrsReportingLabel, {
				tenantGroupTitle: tenantGroup.title,
			}),
			application: 'OKRS_REPORTING',
			tenantGroupId: tenantGroup.tenantGroupId,
		});
	}
	return options.sort((a, b) => a.label.localeCompare(b.label));
};

const processResults = (
	dtos: CredentialsDto[],
	availableApplications: ApplicationOption[],
): ClientCredentialsData[] => {
	const getApplicationDisplay = (dto: CredentialsDto) => {
		const app = availableApplications.find(
			(app) =>
				app.application == dto.application &&
				app.tenantGroupId == dto.tenantGroupId,
		);
		return app?.label || '';
	};

	return (dtos || []).map((dto) => {
		return {
			...dto,
			applicationDisplay: getApplicationDisplay(dto),
			createdAt: new Date(dto.createdAt),
			createdBy: `${dto.createdBy.firstName} ${dto.createdBy.lastName}`,
			lastUsedAt: dto.lastUsedAt ? new Date(dto.lastUsedAt) : null,
			deactivatedAt: dto.deactivatedAt
				? new Date(dto.deactivatedAt)
				: null,
			deactivatedBy: dto.deactivatedBy
				? `${dto.deactivatedBy.firstName} ${dto.deactivatedBy.lastName}`
				: null,
		};
	});
};

const getFormattedDate = (value: Date | null, intl: IntlShape) => {
	if (!value) {
		return '';
	}
	return intl.formatDate(value, {
		year: 'numeric',
		month: 'numeric',
		day: 'numeric',
		hour: 'numeric',
		minute: 'numeric',
	});
};

/**
 * Component to show the list of client credentials and action to revoke them
 */
const ClientCredentialsList = () => {
	const appContext = useContext(AppContext);
	const userContext = useContext(UserContext);
	const intl = useIntl();
	const { tenantGroupMap } = appContext;
	const { hasImpersonator: userHasImpersonator } = userContext;
	const [loading, setLoading] = useState<number | boolean>(3);
	const [rows, setRows] = useState<ClientCredentialsData[]>([]);
	const [deactivateSelectedCredentials, setDeactivateSelectedCredentials] =
		useState(false);
	const [showCreateCredentialForm, setShowCreateCredentialForm] =
		useState(false);
	const [filterVisible, setFilterVisible] = useState(false);
	const [filterParams, setFilterParams] =
		useState<ClientCredentialsFilterParams>(
			getEmptyClientCredentialsFilterParams(),
		);
	const [selectedCredentials, setSelectedCredentials] = useState<
		ClientCredentialsData[]
	>([]);

	const hasActiveFilters = Object.keys(filterParams).some((key) => {
		if (key === 'showDeactivated') {
			return false;
		}

		const value = filterParams[key as ClientCredentialsFilterParamsIndex];

		if (value && value instanceof Set) {
			return !!value.size;
		}

		// a date filter is an array of two nullable Dates
		const isDateFilter = Array.isArray(value) && value.length === 2;

		return isDateFilter ? value[0] || value[1] : value;
	});

	const filterTooltip = intl.formatMessage(
		filterMessages.filterButtonTooltip,
	);

	const availableApplications = React.useMemo(
		() => getApplicationOptions(tenantGroupMap, appContext, intl),
		[tenantGroupMap, appContext, intl],
	);

	const loadClientCredentials = async () => {
		const { results, success, message } = (await get(
			'/io/v1/credentials',
		)) as CredentialsListResponse;

		if (!success) {
			appContext.showToast({
				message,
				type: ToastType.DANGER,
			});
		} else {
			setRows(processResults(results, availableApplications));
		}
		setLoading(false);
	};

	const deactivateClientCredentials = async () => {
		for (const credential of selectedCredentials.filter(
			(cred) => !cred.deactivatedAt,
		)) {
			await requestWithErrorHandling({
				method: 'post',
				url: `/io/v1/credentials/${credential.id}/deactivate`,
				appContext,
				intl,
			});
		}
		setDeactivateSelectedCredentials(false);
		await loadClientCredentials();
	};

	useEffect(() => {
		void loadClientCredentials();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const columns = useMemo<Column<ClientCredentialsData>[]>(() => {
		const cols: Column<ClientCredentialsData>[] = [
			{
				id: 'description',
				label: intl.formatMessage(messages.descriptionLabel),
			},
			{
				id: 'applicationDisplay',
				label: intl.formatMessage(messages.applicationLabel),
			},
			{
				id: 'createdAt',
				label: intl.formatMessage(messages.createdAtLabel),
				cell: {
					label: ({ value }: { value: Date }) =>
						getFormattedDate(value, intl),
				},
			},
			{
				id: 'createdBy',
				label: intl.formatMessage(messages.createdByLabel),
			},
			{
				id: 'lastUsedAt',
				label: intl.formatMessage(messages.lastUsedAtLabel),
				cell: {
					label: ({ value }: { value: Date }) =>
						getFormattedDate(value, intl),
				},
			},
			{
				id: 'id',
				label: intl.formatMessage(messages.idLabel),
				width: 250,
			},
		];

		if (filterParams.showDeactivated) {
			cols.splice(
				5,
				0,
				{
					id: 'deactivatedAt',
					label: intl.formatMessage(messages.deactivatedAtLabel),
					cell: {
						label: ({ value }: { value: Date }) =>
							getFormattedDate(value, intl),
					},
				},
				{
					id: 'deactivatedBy',
					label: intl.formatMessage(messages.deactivatedByLabel),
				},
			);
		}

		return cols;
	}, [intl, filterParams.showDeactivated]);

	const preferencesKey = columns.map((c) => c.id).join('-');
	const preferencesAdapter = useLocalStoragePreferences(preferencesKey);

	const actionsMenu = {
		Component: ({ row }: { row: ClientCredentialsData }) => (
			<>
				<ListItem
					label={intl.formatMessage(
						messages.deactivateCredentialsMenuItem,
					)}
					onActivate={() => {
						setSelectedCredentials([row]);
						setDeactivateSelectedCredentials(true);
					}}
					disabled={!!row.deactivatedAt || userHasImpersonator()}
				/>
			</>
		),
	};

	const toolbarActions: ToolbarAction<ClientCredentialsData>[] = [
		{
			message: messages.deactivateCredentialsMenuItem,
			icon: undefined,
			isEnabled: (credentials: ClientCredentialsData[]): boolean =>
				!userHasImpersonator() &&
				credentials.length > 0 &&
				credentials.some((credential) => !credential.deactivatedAt),
			activateFn: () => setDeactivateSelectedCredentials(true),
		},
	];

	return (
		<>
			<Toolbar label="OAuth2 Credentials Toolbar">
				<ToolbarSection>
					<ToolbarButtonEmpty
						data-testid="toggle-filters"
						aria-label={filterTooltip}
						icon={<Filter />}
						activated={filterVisible}
						onClick={() => {
							setFilterVisible(!filterVisible);
							setFilterParams((currFilterParams) => {
								const { showDeactivated } = currFilterParams;
								return getEmptyClientCredentialsFilterParams({
									showDeactivated,
								});
							});
						}}
						tooltip={filterTooltip}
					/>
					<ToolbarSeparator />
					<ToolbarButtonEmpty
						icon={<PlusCircle />}
						onClick={() => setShowCreateCredentialForm(true)}
						tooltip={intl.formatMessage(
							messages.createClientCredentials,
						)}
						disabled={userHasImpersonator()}
					>
						{intl.formatMessage(messages.createClientCredentials)}
					</ToolbarButtonEmpty>
				</ToolbarSection>
				<ToolbarSection>
					<Switch
						label={intl.formatMessage(
							messages.showDeactivatedCredentialsSwitch,
						)}
						checked={filterParams.showDeactivated}
						onChange={(isEnabled: boolean) =>
							setFilterParams((currFilterParams) => {
								return {
									...currFilterParams,
									showDeactivated: isEnabled,
									deactivatedBy: null,
									deactivatedAt: [null, null],
								};
							})
						}
					/>
					{toolbarActions.length ? (
						<ToolbarActionsMenu
							rowData={selectedCredentials}
							actions={toolbarActions}
							message={messages.actionsButton}
							alignRight={true}
							hideDisabled={false}
						/>
					) : null}
				</ToolbarSection>
			</Toolbar>

			<Container aria-label="Content Container">
				<FilterContainer
					aria-hidden={!filterVisible}
					aria-label="Filter Panel"
					filterVisible={filterVisible}
				>
					{filterVisible && (
						<ClientCredentialsFilter
							onCloseFilterClick={() => {
								setFilterParams((currFilterParams) => {
									const { showDeactivated } =
										currFilterParams;
									return getEmptyClientCredentialsFilterParams(
										{
											showDeactivated,
										},
									);
								});
								setFilterVisible(false);
							}}
							filterParams={filterParams}
							setFilterParams={setFilterParams}
							applicationOptions={availableApplications}
						/>
					)}
				</FilterContainer>
				<MainContainer
					filterVisible={filterVisible}
					aria-label="Main Content Container"
				>
					{hasActiveFilters ? (
						<FilterBanner
							matching={0}
							total={0}
							onClear={() =>
								setFilterParams((currFilterParams) => {
									const { showDeactivated } =
										currFilterParams;
									return getEmptyClientCredentialsFilterParams(
										{
											showDeactivated,
										},
									);
								})
							}
						/>
					) : null}
					<Grid
						columns={columns}
						rows={rows}
						loading={loading}
						defaultSort={[
							{
								columnId: 'description',
								direction: 'asc',
							},
						]}
						selectionMode="multi"
						emptyContent={
							<EmptyState
								header={intl.formatMessage(messages.noData)}
							/>
						}
						filter={getClientCredentialsFilterFunction(
							filterParams,
						)}
						actionsMenu={actionsMenu}
						onSelectionChange={(selection) => {
							const selectedRows = rows.filter((row) =>
								selection.has(row.id),
							);
							setSelectedCredentials(selectedRows);
						}}
						preferencesAdapter={preferencesAdapter}
					/>
					{deactivateSelectedCredentials && (
						<DeactivateClientCredentialsModal
							onConfirm={() => deactivateClientCredentials()}
							onCancel={() =>
								setDeactivateSelectedCredentials(false)
							}
						/>
					)}
					{showCreateCredentialForm && (
						<NewClientCredentialsModal
							onConfirm={() => setShowCreateCredentialForm(false)}
							onCreated={() => loadClientCredentials()}
							availableApplications={availableApplications}
						/>
					)}
				</MainContainer>
			</Container>
		</>
	);
};

export default ClientCredentialsList;
