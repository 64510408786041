import React, { useContext, useState } from 'react';
import { AppContext } from '../../../context';
import { PageHeader } from '../../../components/common/Wrappers';
import ConnectionGroup from './ConnectionGroup';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './ConnectionsPage.messages';
import styled from 'styled-components';
import { Spinner, SIZE_XLARGE, Tab, TabGroup } from '@planview/pv-uikit';
import usePolling from '../../../hooks/usePolling';
import { spacingPx } from '@planview/pv-utilities';

Tab.displayName = 'Tab';
const Container = styled.div`
	height: 100%;
	overflow: auto;
	padding: ${spacingPx.small} 24px;
`;
const StyledTabGroup = styled(TabGroup)`
	margin: ${spacingPx.small} 0 ${spacingPx.small} -8px;
`;

const SpinnerContainer = styled.div`
	height: 200px;
`;

const TAB_PRODUCTION = 'production';
const TAB_SANDBOX = 'sandbox';

const ConnectionsPage = () => {
	const appContext = useContext(AppContext);
	const intl = useIntl();
	const { formatMessage } = intl;
	const enableConnectionsModule =
		appContext.featureFlags.enableConnectionsModule;
	const [activeTab, setActiveTab] = useState(TAB_PRODUCTION);
	const [connectionGroups, setConnectionGroups] = useState(null);

	usePolling({
		enabled: enableConnectionsModule,
		url: `/api/connections?sandbox=${activeTab === TAB_SANDBOX}`,
		callback: (result) => {
			setConnectionGroups(result.groups);
		},
	});

	const changeTab = (newTab) => {
		setConnectionGroups(null);
		setActiveTab(newTab);
	};

	if (!enableConnectionsModule) {
		return <Container>Connections feature is not enabled</Container>;
	}

	return (
		<Container>
			<PageHeader>
				<FormattedMessage {...messages.connectionsTitle} />
			</PageHeader>

			<StyledTabGroup defaultIndex={0}>
				<Tab
					label={formatMessage(messages.productionTab)}
					onActivate={() => changeTab(TAB_PRODUCTION)}
				/>
				<Tab
					label={formatMessage(messages.sandboxTab)}
					onActivate={() => changeTab(TAB_SANDBOX)}
				/>
			</StyledTabGroup>
			{connectionGroups == null ? (
				<SpinnerContainer>
					<Spinner size={SIZE_XLARGE} />
				</SpinnerContainer>
			) : null}
			{connectionGroups != null && connectionGroups.length === 0
				? formatMessage(messages.noProductsMessage)
				: null}
			{connectionGroups != null &&
				connectionGroups.map((group, idx) => (
					<ConnectionGroup group={group} key={idx}></ConnectionGroup>
				))}
		</Container>
	);
};

export default ConnectionsPage;
