import { defineMessages } from 'react-intl';

const messages = defineMessages({
	connectionsTitle: {
		id: 'admin.connections.connectionsTitle',
		description: 'Header for Connections tab',
		defaultMessage: 'Connections',
	},
	productionTab: {
		id: 'admin.connections.productionTab',
		description: 'Title for Production tab',
		defaultMessage: 'Production',
	},
	sandboxTab: {
		id: 'admin.connections.sandboxTab',
		description: 'Title for Sandbox tab',
		defaultMessage: 'Sandbox',
	},
	connectToTheseProducts: {
		id: 'admin.connections.connectToTheseProducts',
		description: 'Info text on connections tab',
		defaultMessage: 'Connect to these products:',
	},
	connectButton: {
		id: 'admin.connections.connectButton',
		description: 'Button text to connect two products',
		defaultMessage: 'Connect',
	},
	disconnectButton: {
		id: 'admin.connections.disconnectButton',
		description: 'Button text to disconnect two products.',
		defaultMessage: 'Disconnect',
	},
	connectedHoverText: {
		id: 'admin.connections.connectedHoverText',
		description:
			'Hover text on a green icon indicating two products are connected',
		defaultMessage: 'Connected',
	},
	connectingHoverText: {
		id: 'admin.connections.connectingHoverText',
		description:
			'Hover text on a icon indicating two products are in process/queued to be connected',
		defaultMessage: 'Connection in progress',
	},
	disconnectingHoverText: {
		id: 'admin.connections.disconnectingHoverText',
		description:
			'Hover text on a icon indicating two products are in process/queued to be disconnected',
		defaultMessage: 'Disconnection in progress',
	},
	errorHoverText: {
		id: 'admin.connections.errorHoverText',
		description:
			'Hover text on a icon indicating two products could not be connected',
		defaultMessage:
			'The products could not be connected. Please submit a ticket to customer care.',
	},
	noProductsMessage: {
		id: 'admin.connections.noProductsMessage',
		description:
			'Message shown if the customer has no products that can be connected',
		defaultMessage:
			'There are no products available that can be connected.',
	},
	confirmConnectHeader: {
		id: 'admin.connections.confirmConnectHeader',
		description:
			'Header text for the message shown when confirming connection',
		defaultMessage: 'Connect Products?',
	},
	confirmConnectMessage: {
		id: 'admin.connections.confirmConnectMessage',
		description: 'Message shown when confirming connection',
		defaultMessage:
			'<p>You are connecting the following instance of {product1} with {product2}:</p>' +
			'<item>{product1Description}</item>' +
			'<p>It may take 15 minute for applications to connect. Once connected, you will be unable to change the connection. To disable the connection, you can submit a ticket to customer care.</p>',
	},
	confirmDisconnectHeader: {
		id: 'admin.connections.confirmDisconnectHeader',
		description:
			'Header text for the message shown when confirming disconnecting two products',
		defaultMessage: 'Disconnect Products?',
	},
	confirmDisconnectMessage: {
		id: 'admin.connections.confirmDisconnectMessage',
		description: 'Message shown when confirming disconnecting two products',
		defaultMessage:
			'<p>You are disconnecting the following instance of {product1} with {product2}:</p>' +
			'<item>{product1Description}</item>' +
			'<p>It will take about a minute for applications to disconnect.</p>',
	},
});

export default messages;
