import React, { useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { AppContext } from '../../../context';
import OKRSettingsTile from './OKRs/OKRSettingsTile';
import PlanviewMeSettingsTile from './PlanviewMe/PlanviewMeSettingsTile';
import messages from './ServiceSettings.messages';
import styled from 'styled-components';
import { spacingPx } from '@planview/pv-utilities';
import LogBookSettingsTile from './LogBook/LogBookSettingsTile';

const SettingsTileContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: space-between;
	row-gap: ${spacingPx.small};
	padding: ${spacingPx.large} ${spacingPx.large} 0 ${spacingPx.large};
`;

const IMAGE_SCALE: number = 0.18;

// A react container for showing service settings tiles
const ServiceSettings = () => {
	const { tenantGroupMap, featureFlags, applications, refreshAppData } =
		useContext(AppContext);
	const { enableLogbook } = featureFlags;

	// ensure we have latest application data for the user, since some permission checks require that
	useEffect(() => {
		refreshAppData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	let hasAnySettingsTiles = false;
	const settingsTiles = [];
	if (tenantGroupMap.size > 0) {
		for (const tenantGroup of tenantGroupMap.values()) {
			const { tenantGroupId, sandbox, primary, title } = tenantGroup;

			// always show the OKRs Settings Tile
			const showOkrSettingsTile = true;

			// only show the Planview.Me Settings Tile for production and primary sandbox tenant groups:
			// * when a Planview.Me tenant exists, OR
			// * when a Portfolios tenant exists
			const tenantGroupIsProductionOrPrimarySandbox = !sandbox || primary;
			const hasPlanviewMeOrPortfoliosTenant = applications.some(
				(application) =>
					application.tenantGroupId === tenantGroupId &&
					(application.app == 'PLANVIEW_ME' ||
						application.app == 'E1_PRM'),
			);

			const showPlanviewMeSettingsTile =
				hasPlanviewMeOrPortfoliosTenant &&
				tenantGroupIsProductionOrPrimarySandbox;

			const showLogbookTile = enableLogbook;

			settingsTiles.push(
				<SettingsTileContainer key={tenantGroupId}>
					{showPlanviewMeSettingsTile ? (
						<PlanviewMeSettingsTile
							sandbox={sandbox}
							tenantGroupId={tenantGroupId}
							showPrimaryLabel={primary}
							title={title}
							imageScale={IMAGE_SCALE}
						/>
					) : null}
					{showOkrSettingsTile ? (
						<OKRSettingsTile
							sandbox={sandbox}
							tenantGroupId={tenantGroupId}
							showPrimaryLabel={primary}
							title={title}
							imageScale={IMAGE_SCALE}
						/>
					) : null}
					{showLogbookTile ? (
						<LogBookSettingsTile
							sandbox={sandbox}
							tenantGroupId={tenantGroupId}
							showPrimaryLabel={primary}
							title={title}
							imageScale={IMAGE_SCALE}
						/>
					) : null}
				</SettingsTileContainer>,
			);

			// if we had any settings tiles added as a part of this tenant group, set hasAnySettingsTiles to true
			hasAnySettingsTiles =
				hasAnySettingsTiles ||
				showOkrSettingsTile ||
				showPlanviewMeSettingsTile;
		}
	}

	if (hasAnySettingsTiles) {
		return <>{settingsTiles}</>;
	} else {
		return (
			<SettingsTileContainer>
				<FormattedMessage {...messages.missingProductsMessage} />
			</SettingsTileContainer>
		);
	}
};

export default ServiceSettings;
