import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import {
	Counter,
	Divider,
	Input,
	Radio,
	RightTooltip,
	SIZE_XLARGE,
	Spinner,
	Switch,
	Textarea as BaseTextarea,
} from '@planview/pv-uikit';
import { Grid, GridCellDefault, GridCellLink } from '@planview/pv-grid';
import { color, sizePx, spacingPx } from '@planview/pv-utilities';
import {
	ButtonEmpty,
	ButtonPrimary,
} from '../../../components/common/button/Button';
import { HBox, VBox } from '../../../components/common/Layout';
import { ConfirmationModal } from '../../../components/common/modal/Modal';
import AdvancedConfiguration from './AdvancedConfiguration';
import { AppContext, UserContext } from '../../../context';
import { put, requestWithErrorHandling } from '../../../hooks/request/request';
import messages from './SSOSettings.messages';
import { hasUniqueSamlEntityId } from '../../../helpers/util';
import { ToastType } from '../../../types/toast';
import { Copy, Help, Mail } from '@planview/pv-icons';
import {
	DISMISS_AFTER_FIVE_SECONDS,
	DISMISS_AFTER_TWO_SECONDS,
} from '../../../components/common/toast/Toast';

Switch.displayName = 'Switch';

const Section = styled(VBox)`
	flex-basis: auto;
	line-height: ${sizePx.small};
	padding: ${spacingPx.large};
`;

const HeaderDiv = styled.div`
	display: flex;
	align-items: center;
	font-size: 16;
	font-weight: 400;
	padding: 0 ${spacingPx.small} ${spacingPx.large} ${spacingPx.small};
`;

const SectionTitle = styled.div`
	display: flex;
	align-items: center;
	font-weight: 600;
	line-height: ${sizePx.smallCompact};
	padding-bottom: ${spacingPx.small};
`;

const SectionLabel = styled.div`
	padding-left: ${spacingPx.small};
`;

const SectionDiv = styled.div`
	font-size: 13px;
	line-height: 16px;
	padding: 0 ${spacingPx.small} ${spacingPx.medium} ${spacingPx.small};
`;

const SectionInput = styled.div`
	display: flex;
	align-items: center;
`;

const RightAlignedDiv = styled.div`
	margin-left: auto;
`;

const ConfirmationDiv = styled.div`
	display: flex;
	align-items: center;
	padding-bottom: ${spacingPx.medium};
`;

const TestDiv = styled.div`
	display: flex;
	align-items: center;
	padding-top: ${spacingPx.medium};
	font-size: 13px;
`;

const InfoDiv = styled.div`
	color: ${color.iconInfo};
	padding: 0 0 ${spacingPx.small};
`;

const ExpirationDiv = styled.div`
	color: ${color.iconInfo};
	padding-left: 56px;
`;

const WarningExpirationDiv = styled.div`
	color: ${color.iconWarning};
	padding-left: 56px;
`;

const ExpiredExpirationDiv = styled.div`
	color: ${color.iconError};
	padding-left: 56px;
`;

const RadioDiv = styled.div`
	width: 300px;
	min-width: 300px;
`;

const URLInput = styled(Input)`
	margin-left: ${spacingPx.small};
	height: ${sizePx.small};
	width: 100%;
`;

const Textarea = styled(BaseTextarea)`
	margin-left: ${spacingPx.small};
	width: 100%;
`;

const Toolbar = styled(HBox)`
	align-content: flex-start;
	margin-top: ${spacingPx.medium};
	margin-left: ${spacingPx.small};
`;

const SaveButton = styled(ButtonPrimary)`
	padding: 0 ${spacingPx.small};
	margin-left: ${spacingPx.small};
`;

export const HelpIcon = (message) => {
	return (
		<div style={{ paddingLeft: spacingPx.small }}>
			<RightTooltip text={message} enterDelay={200}>
				<Help style={{ color: color.iconNormal }} />
			</RightTooltip>
		</div>
	);
};

const getRowInfo = (rows) => {
	let data = '';

	rows.forEach((row) => {
		const detail = row.details;
		const link = row.link.link || row.link.label;

		data = data + '\n\n' + detail + ': ' + link;
	});
	return data;
};

const getExpiration = (tokenExpiration) => {
	if (!tokenExpiration) {
		return;
	}

	const today = new Date();
	const nextWeek = new Date(new Date().setDate(today.getDate() + 7));
	const expDate = new Date(tokenExpiration);

	if (today > expDate) {
		return (
			<ExpiredExpirationDiv>
				<FormattedMessage
					{...messages.metadataExpiredLabel}
					values={{ expTime: expDate.toString() }}
				/>
			</ExpiredExpirationDiv>
		);
	} else if (nextWeek > expDate) {
		return (
			<WarningExpirationDiv>
				<FormattedMessage
					{...messages.metadataExpirationLabel}
					values={{ expTime: expDate.toString() }}
				/>
			</WarningExpirationDiv>
		);
	} else {
		return (
			<ExpirationDiv>
				<FormattedMessage
					{...messages.metadataExpirationLabel}
					values={{ expTime: expDate.toString() }}
				/>
			</ExpirationDiv>
		);
	}
};

const handleCopyClick = (rows, appContext, intl) => {
	const { showToast } = appContext;
	let copyText = intl.formatMessage(messages.itConfigurationSubHeader) + '\n';
	copyText += getRowInfo(rows);

	navigator.clipboard.writeText(copyText).then(
		() => {
			showToast({
				dismissAfter: DISMISS_AFTER_TWO_SECONDS,
				message: intl.formatMessage(messages.copyToClipboardSuccess),
				type: ToastType.SUCCESS,
			});
		},
		() => {
			showToast({
				dismissAfter: DISMISS_AFTER_FIVE_SECONDS,
				message: intl.formatMessage(messages.copyToClipboardError),
				type: ToastType.DANGER,
			});
		},
	);
};

const handleMailClick = (rows, intl) => {
	const subject = intl.formatMessage(messages.itConfigurationEmailSubject);
	const body =
		intl.formatMessage(messages.itConfigurationEmailInstructions) +
		'\n' +
		getRowInfo(rows);

	window.location.href =
		'mailto:?body=' +
		encodeURIComponent(body) +
		'&subject=' +
		encodeURIComponent(subject);
};

const validateSettings = ({
	enableSSO,
	enableUrl,
	enableXml,
	samlRequestedAttributes,
}) => {
	const enabled = enableUrl || enableXml;

	if (enableSSO && !enabled) {
		return { message: messages.noSelectionError };
	}

	if (samlRequestedAttributes && samlRequestedAttributes.length > 0) {
		const hasEmptyValues = samlRequestedAttributes.some((attr) => {
			return !attr.name || !attr.nameFormat;
		});
		if (hasEmptyValues) {
			return { message: messages.noAttributeValueError };
		}
	}

	return false;
};

const SaveConfirmationDialog = ({ message, onConfirm, onCancel, isSaving }) => {
	const { formatMessage } = useIntl();

	return (
		<ConfirmationModal
			onConfirm={onConfirm}
			onCancel={onCancel}
			confirmText={
				isSaving
					? formatMessage(messages.yesButtonWhileSaving)
					: formatMessage(messages.yesButton)
			}
			headerText={formatMessage(messages.saveHeader)}
			disableConfirm={isSaving}
			message={message}
		/>
	);
};

const SSOSettings = () => {
	const appContext = useContext(AppContext);
	const userContext = useContext(UserContext);
	const intl = useIntl();
	const { formatMessage } = intl;
	const [loading, setLoading] = useState(true);
	const [enableSSO, setEnableSSO] = useState(false);
	const [showAdvancedConfig, setShowAdvancedConfig] = useState(false);
	const [enableUrl, setEnableUrl] = useState(false);
	const [enableXml, setEnableXml] = useState(false);
	const [enableEmail, setEnableEmail] = useState(false);
	const [metadataUrl, setMetadataUrl] = useState('');
	const [metadataXml, setMetadataXml] = useState('');
	const [samlSigningAlgorithm, setSamlSigningAlgorithm] = useState();
	const [samlNameIDPolicyFormat, setSamlNameIDPolicyFormat] = useState();
	const [samlNameIdLookupType, setSamlNameIdLookupType] = useState();
	const [metadataAttribute, setMetadataAttribute] = useState();
	const [samlRequestedAttributes, setSamlRequestedAttributes] = useState([]);
	const [ssoConfig, setSsoConfig] = useState();
	const [showSaveDialog, setShowSaveDialog] = useState(false);
	const [showEnableDialog, setShowEnableDialog] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const [spMetadataUrl, setSpMetadataUrl] = useState('');
	const [samlEntityId, setSamlEntityId] = useState('');
	const [samlAcsUrl, setSamlAcsUrl] = useState('');
	const [tokenExpiration, setTokenExpiration] = useState('');
	const [showTokenExpiration, setShowTokenExpiration] = useState(false);

	const setIntialConfig = (config = ssoConfig) => {
		setEnableEmail(config.enableEmail);

		// sso config
		setEnableUrl(config.enableUrl);
		setEnableXml(config.enableXml);
		setEnableSSO(config.enableSSO);
		setMetadataUrl(config.metadataUrl || '');
		setMetadataXml(config.metadataXml || '');
		setSamlSigningAlgorithm(config.samlSigningAlgorithm);
		setSamlNameIDPolicyFormat(config.samlNameIDPolicyFormat);
		setSamlNameIdLookupType(config.samlNameIdLookupType);
		setMetadataAttribute(config.metadataAttribute || '');
		setSamlRequestedAttributes(config.samlRequestedAttributes || []);
		setSpMetadataUrl(config.spMetadataUrl || '');
		setSamlEntityId(config.samlEntityId || '');
		setSamlAcsUrl(config.samlAcsUrl || '');
		setTokenExpiration(config.tokenExpiration || '');
	};

	const testSignInUrl = `${location.origin}/api/loginsso/test/${userContext.customer.id}`;
	const testSSO = () => {
		window.open(
			testSignInUrl,
			'ssotest',
			'menubar=0,resizable=1,width=1024,height=600,top=100,left=100',
		);
	};

	const columns = [
		{
			id: 'details',
			label: intl.formatMessage(messages.configurationDetailsColumn),
			sortable: false,
			resizable: false,
			movable: false,
			width: 350,
		},
		{
			id: 'link',
			label: intl.formatMessage(messages.linkValueColumn),
			sortable: false,
			resizable: false,
			movable: false,
			cell: {
				Renderer: ({ value, tabIndex }) => {
					const hasLink = !!value.link;
					return hasLink ? (
						<GridCellLink
							label={value.label}
							tabIndex={tabIndex}
							href={value.link}
							target="_blank"
							rel="noopener noreferrer"
						/>
					) : (
						<GridCellDefault
							label={value.label}
							tabIndex={tabIndex}
						/>
					);
				},
			},
			width: 700,
		},
	];

	const rows = React.useMemo(
		() => [
			{
				id: 0,
				details: formatMessage(messages.documentationLabel),
				link: {
					label: formatMessage(messages.samlHelpText),
					link: 'https://success.planview.com/Planview_Admin/Planview_Admin_Settings/Single_Sign-On_Settings_for_Planview_Admin',
				},
			},
			{
				id: 1,
				details: formatMessage(messages.spMetadataUrlLabel),
				link: { label: spMetadataUrl, link: spMetadataUrl },
			},
			{
				id: 2,
				details: formatMessage(messages.samlEntityIdLabel),
				link: { label: samlEntityId },
			},
			{
				id: 3,
				details: formatMessage(messages.samlAcsUrlLabel),
				link: { label: samlAcsUrl },
			},
			{
				id: 4,
				details: formatMessage(messages.regionLabel),
				link: { label: appContext.region },
			},
			{
				id: 5,
				details: formatMessage(messages.nameIdFormatLabel),
				link: {
					label:
						samlNameIdLookupType === 'EMAIL'
							? samlNameIDPolicyFormat
							: 'urn:oasis:names:tc:SAML:1.1:nameid-format:unspecified',
				},
			},
			{
				id: 6,
				details: formatMessage(messages.testSignInUrlLabel),
				link: { label: testSignInUrl, link: testSignInUrl },
			},
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			spMetadataUrl,
			samlEntityId,
			samlAcsUrl,
			samlNameIDPolicyFormat,
			samlNameIdLookupType,
			testSignInUrl,
		],
	);

	const isDirty = () => {
		const data = {
			...ssoConfig,
			enableEmail,
			enableSSO,
			enableUrl,
			enableXml,
			metadataUrl,
			metadataXml,
			metadataAttribute,
			samlRequestedAttributes,
			samlSigningAlgorithm,
			samlNameIDPolicyFormat,
			samlNameIdLookupType,
		};

		return JSON.stringify(data) !== JSON.stringify(ssoConfig);
	};

	async function getSettings() {
		const { success, ...config } = await requestWithErrorHandling({
			method: 'get',
			url: `/io/v1/customer/details`,
			appContext,
			intl,
		});

		if (success) {
			setSsoConfig(config);
			setIntialConfig(config);
			const hasAdditionalAttributes =
				config.samlRequestedAttributes.length > 0 ||
				!!config.metadataAttribute;
			setShowAdvancedConfig(hasAdditionalAttributes);
			setShowTokenExpiration(config.enableXml);
		}
		setLoading(false);
	}

	const saveConfig = async () => {
		const data = {
			enableEmail,
			enableSSO,
			enableUrl,
			enableXml,
			metadataUrl,
			metadataXml,
			metadataAttribute,
			samlRequestedAttributes,
			samlSigningAlgorithm,
			samlNameIDPolicyFormat,
			samlNameIdLookupType,
		};
		const { success, message } = await put(`/io/v1/customer/details`, data);

		// Clear the XML/URL depending on the option enabled
		if (success) {
			if (enableUrl) {
				setMetadataXml('');
			} else {
				setMetadataUrl('');
			}
			setSsoConfig(data);
		}

		appContext.showToast({
			message: message,
			type: success ? ToastType.SUCCESS : ToastType.DANGER,
		});

		return success;
	};

	const toggleSSO = async (enabled) => {
		const data = {
			enableEmail,
			enableSSO: enabled,
			enableUrl,
			enableXml,
			metadataUrl,
			metadataXml,
			metadataAttribute,
			samlRequestedAttributes,
			samlSigningAlgorithm,
			samlNameIDPolicyFormat,
			samlNameIdLookupType,
		};
		if (!metadataUrl && !metadataXml && enabled) {
			appContext.showToast({
				message: formatMessage(messages.toggleErrorMessage),
				type: ToastType.DANGER,
			});
			setEnableSSO(false);
			return false;
		}

		const { success, message } = await put(`/io/v1/customer/details`, data);

		// Clear the XML/URL depending on the option enabled
		if (success) {
			if (enableUrl) {
				setMetadataXml('');
			} else {
				setMetadataUrl('');
			}
			setSsoConfig(data);
		}

		appContext.showToast({
			message: message,
			type: success ? ToastType.SUCCESS : ToastType.DANGER,
		});

		return success;
	};

	const getSaveMessage = () => {
		const origEnableSso = ssoConfig.enableSSO;
		let message = formatMessage(messages.saveSettingsNoSsoChange);
		if (origEnableSso !== enableSSO) {
			message = enableSSO
				? formatMessage(messages.enableContent)
				: formatMessage(messages.disableContent);
		}
		// if there's a change to the samlNameIdLookupType and if the samlEntityId does not have the
		// unique identifier, then we need to show a warning message that the samlEntityId will be
		// modified on save
		const origSamlNameIdLookupType = ssoConfig.samlNameIdLookupType;
		if (
			enableSSO &&
			samlNameIdLookupType !== origSamlNameIdLookupType &&
			samlNameIdLookupType !== 'EMAIL' &&
			!hasUniqueSamlEntityId(samlEntityId)
		) {
			// TODO: i18n after review with product team
			message = `${message}\n Changing the SAML Name ID Lookup Type will modify the SAML Entity ID
			and require changes to SSO configuration in your IDP.
			After saving, provide the new SAML Identifier / Entity ID to your team that manages your IDP.`;
		}
		return message;
	};

	useEffect(() => {
		getSettings();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return loading ? (
		<Spinner size={SIZE_XLARGE} />
	) : (
		<>
			<Section data-testid="account-settings">
				<HeaderDiv>
					<FormattedMessage {...messages.ssoHeader} />
					{HelpIcon(formatMessage(messages.ssoConfigHeaderTooltip))}
					<RightAlignedDiv>
						<Switch
							alignment="RIGHT_ALIGNED"
							checked={enableSSO}
							label={formatMessage(messages.enableSSO)}
							onChange={(enabled) => {
								if (isDirty() && enabled) {
									appContext.showToast({
										message: formatMessage(
											messages.toggleWarning,
										),
										type: ToastType.DANGER,
									});
								} else {
									setEnableSSO(enabled);
									setShowEnableDialog(true);
								}
							}}
						/>
					</RightAlignedDiv>
				</HeaderDiv>
				<SectionDiv>
					<FormattedMessage {...messages.configurationInstructions} />
					<div style={{ paddingTop: spacingPx.small }}>
						<li>
							{formatMessage(
								messages.configurationInstructionsLine1,
							)}
						</li>
						<li>
							{formatMessage(
								messages.configurationInstructionsLine2,
							)}
						</li>
						<li>
							{formatMessage(
								messages.configurationInstructionsLine3,
							)}
						</li>
					</div>
				</SectionDiv>

				<SectionDiv>
					<SectionTitle>
						<Counter count={1} kind="PRIMARY" />
						<SectionLabel style={{ paddingLeft: spacingPx.small }}>
							<FormattedMessage
								{...messages.itConfigurationSubHeader}
							/>
						</SectionLabel>
						{HelpIcon(formatMessage(messages.ssoITTooltip))}
						<ButtonEmpty
							icon={<Copy />}
							message={formatMessage(messages.copyAllButton)}
							onClick={() => {
								handleCopyClick(rows, appContext, intl);
							}}
						/>
						<ButtonEmpty
							icon={<Mail />}
							message={formatMessage(messages.mailToButton)}
							onClick={() => {
								handleMailClick(rows, intl);
							}}
						/>
					</SectionTitle>

					<Grid columns={columns} rows={rows} selectionMode="none" />
				</SectionDiv>

				<SectionDiv>
					<SectionTitle>
						<Counter count={2} kind="PRIMARY" />
						<SectionLabel>
							<FormattedMessage
								{...messages.inputConfigurationSubHeader}
							/>
						</SectionLabel>
						{HelpIcon(
							formatMessage(
								messages.inputConfigurationSubHeaderTooltip,
							),
						)}
					</SectionTitle>

					<SectionInput>
						<RadioDiv>
							<Radio
								value={'url'}
								label={formatMessage(
									messages.samlMetadataUrlLabel,
								)}
								selected={enableUrl}
								onChange={(checked) => {
									setEnableUrl(checked);
									setEnableXml(!checked);
								}}
							/>
						</RadioDiv>
						{HelpIcon(
							formatMessage(messages.helpTooltipSamlMetadataUrl),
						)}
						<URLInput
							value={metadataUrl}
							onChange={(value) => setMetadataUrl(value)}
							nativeProps={{
								'aria-label': formatMessage(
									messages.samlMetadataUrlPlaceholder,
								),
							}}
							placeholder={formatMessage(
								messages.samlMetadataUrlPlaceholder,
							)}
							disabled={!enableUrl}
						/>
					</SectionInput>

					<SectionDiv>
						{enableUrl && (
							<InfoDiv>
								<li>{formatMessage(messages.noteItem1)}</li>
								<li>{formatMessage(messages.noteItem2)}</li>
							</InfoDiv>
						)}
					</SectionDiv>

					<SectionInput>
						<RadioDiv>
							<Radio
								value={'xml'}
								label={formatMessage(
									messages.samlMetadataXmlLabel,
								)}
								selected={enableXml}
								onChange={(checked) => {
									setEnableUrl(!checked);
									setEnableXml(checked);
								}}
							/>
						</RadioDiv>
						{HelpIcon(
							formatMessage(messages.helpTooltipSamlMetadataXml),
						)}
						<Textarea
							value={metadataXml}
							placeholder={formatMessage(
								messages.samlMetadataXmlPlaceholder,
							)}
							nativeProps={{
								'aria-label': formatMessage(
									messages.samlMetadataXmlPlaceholder,
								),
							}}
							onChange={(value) => setMetadataXml(value)}
							disabled={!enableXml}
						/>
					</SectionInput>

					{enableXml && showTokenExpiration && (
						<SectionInput>
							<RadioDiv></RadioDiv>
							{getExpiration(tokenExpiration)}
						</SectionInput>
					)}
				</SectionDiv>

				<Divider />

				<SectionDiv>
					<SectionTitle>
						<Counter count={3} kind="PRIMARY" />
						<SectionLabel>
							<FormattedMessage
								{...messages.advancedConfigurationSubHeader}
							/>
						</SectionLabel>
						{HelpIcon(
							formatMessage(
								messages.helpTooltipAdvancedConfiguration,
							),
						)}
						<RightAlignedDiv>
							<Switch
								data-testid="advancedConfigSwitch"
								checked={showAdvancedConfig}
								onChange={(enabled) =>
									setShowAdvancedConfig(enabled)
								}
							/>
						</RightAlignedDiv>
					</SectionTitle>
					{showAdvancedConfig && (
						<AdvancedConfiguration
							samlRequestedAttributes={samlRequestedAttributes}
							setSamlRequestedAttributes={
								setSamlRequestedAttributes
							}
							metadataAttribute={metadataAttribute}
							setMetadataAttribute={setMetadataAttribute}
							samlSigningAlgorithm={samlSigningAlgorithm}
							setSamlSigningAlgorithm={setSamlSigningAlgorithm}
							samlNameIDPolicyFormat={samlNameIDPolicyFormat}
							setSamlNameIDPolicyFormat={
								setSamlNameIDPolicyFormat
							}
							samlNameIdLookupType={samlNameIdLookupType}
							setSamlNameIdLookupType={setSamlNameIdLookupType}
						/>
					)}
				</SectionDiv>

				<ConfirmationDiv>
					<RightAlignedDiv>
						<Toolbar>
							<ButtonEmpty
								message={messages.cancel}
								onClick={() => setIntialConfig()}
							/>
							<SaveButton
								message={messages.save}
								disabled={!isDirty()}
								onClick={() => {
									const validationError = validateSettings({
										enableSSO,
										enableUrl,
										enableXml,
										metadataUrl,
										metadataXml,
										metadataAttribute,
										samlRequestedAttributes,
									});
									if (validationError) {
										appContext.showToast({
											message: formatMessage(
												validationError.message,
											),
											type: ToastType.DANGER,
										});
										return;
									}
									setShowSaveDialog(true);
								}}
							/>
						</Toolbar>
					</RightAlignedDiv>
				</ConfirmationDiv>

				<Divider />

				<TestDiv>
					<SectionLabel>
						<FormattedMessage {...messages.testSSOSubheader} />
					</SectionLabel>
					{HelpIcon(formatMessage(messages.testSignInTooltip))}
					<RightAlignedDiv>
						<ButtonPrimary
							message={messages.testSSOButton}
							onClick={() => testSSO()}
							disabled={isDirty()}
						/>
					</RightAlignedDiv>
				</TestDiv>
			</Section>

			{showSaveDialog ? (
				<SaveConfirmationDialog
					isSaving={isSaving}
					message={getSaveMessage()}
					onCancel={() => {
						setShowSaveDialog(false);
					}}
					onConfirm={async () => {
						setIsSaving(true);
						await saveConfig();
						setShowSaveDialog(false);
						setIsSaving(false);
					}}
				/>
			) : null}

			{showEnableDialog ? (
				<SaveConfirmationDialog
					isSaving={isSaving}
					message={
						enableSSO
							? formatMessage(messages.enableContent)
							: formatMessage(messages.disableContent)
					}
					onCancel={() => {
						setShowEnableDialog(false);
						setEnableSSO(!enableSSO);
					}}
					onConfirm={async () => {
						setIsSaving(true);
						await toggleSSO(enableSSO);
						setShowEnableDialog(false);
						setIsSaving(false);
					}}
				/>
			) : null}
		</>
	);
};

export default SSOSettings;
