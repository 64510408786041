import React from 'react';
import styled from 'styled-components';
import { Tile } from '../../../components/home/productTile/ProductTile';
import AppLogo from '../../../components/common/appLogo/AppLogo';
import ChildConnection from './ChildConnection';
import { color, overflow, sizePx } from '@planview/pv-utilities';
import { HBox } from '../../../components/common/Layout';
import messages from './ConnectionsPage.messages';
import { FormattedMessage } from 'react-intl';

const GroupTile = styled(Tile)`
	width: 932px;
`;

const ParentApp = styled.div`
	width: 300px;
`;
const Description = styled.div`
	font-size: 14px;
	color: ${color.textPlaceholder};
	line-height: 1.54;
	${overflow.ellipsis};
`;
const UserDescription = styled(Description)`
	color: ${color.textPrimary};
	font-size: ${sizePx.xsmall};
`;

const ChildSection = styled.div`
	font-size: 13px;
`;

const ConnectionGroup = (props) => {
	const { parent, children } = props.group;
	const { application } = parent.envSelector;

	return (
		<GroupTile>
			<HBox>
				<ParentApp>
					<AppLogo app={application} />
					<UserDescription>{parent.title}</UserDescription>
					<Description>{parent.domain}</Description>
				</ParentApp>

				<ChildSection>
					<FormattedMessage {...messages.connectToTheseProducts} />

					{children.map((child, idx) => (
						<ChildConnection
							key={idx}
							child={child}
							parent={parent}
						></ChildConnection>
					))}
				</ChildSection>
			</HBox>
		</GroupTile>
	);
};

export default ConnectionGroup;
