import { defineMessages } from 'react-intl';

const messages = defineMessages({
	adaptiveWork: {
		id: 'home.application.logo.adaptiveWork',
		description:
			'Alt text for Planview AdaptiveWork logo on home and admin pages',
		defaultMessage: 'Planview AdaptiveWork',
	},
	advisor: {
		id: 'home.application.logo.advisor',
		description:
			'Alt text for Planview Advisor logo on home and admin pages',
		defaultMessage: 'Planview Advisor',
	},
	copilotAdmin: {
		id: 'home.application.logo.copilotAdmin',
		description: 'Internal Planview only app - no need to translate',
		defaultMessage: 'Copilot Admin',
	},
	e1PRM: {
		id: 'home.application.logo.e1Prm',
		description:
			'Alt text for Planview Portfolios logo on home and admin pages',
		defaultMessage: 'Planview Portfolios',
	},
	ea: {
		id: 'home.application.logo.ea',
		description:
			'Alt text for Planview Enterprise Architecture logo on home and admin pages',
		defaultMessage: 'Planview Enterprise Architecture',
	},
	hub: {
		id: 'home.application.logo.hub',
		desscription: 'Alt text for Planview Hub logo on home and admin pages',
		defaultMessage: 'Planview Hub',
	},
	launchpad: {
		id: 'home.application.logo.laucnhpad',
		description:
			'Alt text for Planview Tasktop LaunchPad logo on home and admin pages',
		defaultMessage: 'Planview LaunchPad',
	},
	leanKit: {
		id: 'home.application.logo.leanKit',
		description:
			'Alt text for Planview AgilePlace logo on home and admin pages',
		defaultMessage: 'Planview AgilePlace',
	},
	logBook: {
		id: 'home.application.logo.logBook',
		description:
			'Alt text for Planview LogBook logo on home and admin pages',
		defaultMessage: 'Planview LogBook',
	},
	okrs: {
		id: 'home.application.logo.okrs',
		description: 'Alt text for Planview OKRs logo on home and admin pages',
		defaultMessage: 'Planview OKRs',
	},
	planviewMe: {
		id: 'home.application.logo.planviewme',
		description: 'Alt text for Planview.Me logo on home and admin pages',
		defaultMessage: 'Planview.Me',
	},
	ppmPro: {
		id: 'home.application.logo.ppmPro',
		description:
			'Alt text for Planview PPM Pro logo on home and admin pages',
		defaultMessage: 'Planview PPM Pro',
	},
	projectPlace: {
		id: 'home.application.logo.projectPlace',
		description:
			'Alt text for Planview ProjectPlace logo on home and admin pages',
		defaultMessage: 'Planview ProjectPlace',
	},
	roadmaps: {
		id: 'home.application.logo.roadmaps',
		description:
			'Alt text for Planview Roadmaps logo on home and admin pages',
		defaultMessage: 'Planview Roadmaps',
	},
	teamtap: {
		id: 'home.application.logo.teamtap',
		description:
			'Alt text for Planview IdeaPlace logo on home and admin pages',
		defaultMessage: 'Planview IdeaPlace',
	},
	viz: {
		id: 'home.application.logo.viz',
		description:
			'Alt text for Planview Tasktop Viz logo on home and admin pages',
		defaultMessage: 'Planview Tasktop Viz',
	},
});

export default messages;
