import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { createOrgTagOption } from '../../../helpers/util';
import { AppContext } from '../../../context';
import OrgTagsContext from '../../../context/orgTagsContext';
import { post, requestWithErrorHandling } from '../../../hooks/request/request';
import messages from './NewOrganizationDialog.messages';
import { default as formMessages } from '../../../helpers/validation.messages';
import {
	LicenseDetailExpanded,
	LicenseType,
	TenantLicenseInfoResponseDto,
	UpdateTotalLicensesDto,
} from '../../../types/api/licenses';
import { ApplicationName, Customer } from '../../../types';
import { UpdateCustomerDto } from '../../../types/api/customers';
import { ApiResponse } from '../../../types/api/api';
import { Modal } from '../../../components/common/modal/Modal';
import { preventEventBubbling } from '../../common/PreventEventBubbling';
import {
	Checkbox,
	ComboboxOption,
	DESTRUCTIVE,
	MODAL_MEDIUM,
	MODAL_SMALL,
} from '@planview/pv-uikit';
import { LicensesSection } from './LicenseSection';
import { Combobox, Form, Input } from '@planview/pv-form';
import {
	CautionLabel,
	LeftAlignedWarningIcon,
	modalProps,
	ModalText,
	SectionHeader,
	TDUMSection,
	WarningDiv,
} from '../../../components/common/Dialog';
import { useForm } from '@mantine/form';
import { OrgHierarchySection } from './OrgHierarchySection';
import { OrgHierarchyForm } from '../../../components/common/OrgHierarchyForm';
import EditParentOrganizationDialog from './EditParentOrganizationDialog';
import styled from 'styled-components';

type EditOrganizationDialogProps = {
	onConfirm: () => void;
	onCancel: () => void;
	organization: Customer;
};

type TenantLicenseInfo = {
	tenantId: string | null;
	licenseTypes: LicenseType[];
};

const StyledDiv = styled.div`
	width: 460;
`;

const OrgHierarchyConfirmationModal = ({ onConfirm, onCancel }: modalProps) => {
	const intl = useIntl();
	const [checkboxConfirm, setCheckboxConfirm] = useState(false);

	return (
		<Modal
			data-testid={'org-hierarchy-confirmation-modal'}
			size={MODAL_MEDIUM}
			onConfirm={onConfirm}
			onCancel={onCancel}
			confirmText={intl.formatMessage(
				messages.orgHierarchyConfirmationButton,
			)}
			disableConfirm={!checkboxConfirm}
			cancelText={intl.formatMessage(messages.cancelButton)}
			headerText={intl.formatMessage(messages.orgHierarchyHeader)}
		>
			<ModalText>
				<FormattedMessage {...messages.orgHierarchyMessage} />
			</ModalText>
			<ModalText>
				<FormattedMessage {...messages.orgHierarchyWarning} />
			</ModalText>
			<Checkbox
				defaultChecked={checkboxConfirm}
				label={intl.formatMessage(messages.orgHierarchyCheckboxLabel)}
				onChange={(value) => {
					setCheckboxConfirm(value);
				}}
			/>
		</Modal>
	);
};

const IdeaPlaceConfirmationModal = ({ onConfirm, onCancel }: modalProps) => {
	const intl = useIntl();

	return (
		<Modal
			size={MODAL_SMALL}
			type={DESTRUCTIVE}
			onConfirm={onConfirm}
			onCancel={onCancel}
			confirmText={intl.formatMessage(
				messages.disableTeamTapLicensesHeader,
			)}
			cancelText={intl.formatMessage(messages.cancelButton)}
			headerText={intl.formatMessage(
				messages.disableTeamTapLicensesHeader,
			)}
		>
			<ModalText>
				<FormattedMessage {...messages.disableTeamTapLicensesMessage} />
			</ModalText>
			<ModalText>
				<FormattedMessage {...messages.disableTeamTapLicensesWarning} />
			</ModalText>
		</Modal>
	);
};

const RoadmapsZeroConfirmationModal = ({ onConfirm, onCancel }: modalProps) => {
	const intl = useIntl();

	return (
		<Modal
			size={MODAL_MEDIUM}
			type={DESTRUCTIVE}
			onConfirm={onConfirm}
			onCancel={onCancel}
			confirmText={intl.formatMessage(messages.roadmapsToZeroHeader)}
			cancelText={intl.formatMessage(messages.cancelButton)}
			headerText={intl.formatMessage(messages.roadmapsToZeroHeader)}
		>
			<ModalText>
				<FormattedMessage {...messages.roadmapsToZeroMessage} />
			</ModalText>
			<ModalText>
				<FormattedMessage {...messages.roadmapsToZeroWarning} />
			</ModalText>
		</Modal>
	);
};

const EditOrganizationDialog = (props: EditOrganizationDialogProps) => {
	const { onConfirm, onCancel, organization } = props;
	const intl = useIntl();

	const { id, regulatoryRegion, parentCustomerId, parentCustomerTitle } =
		organization;
	const appContext = useContext(AppContext);
	const orgTagsContext = useContext(OrgTagsContext);
	const {
		topDownUserManagement: showTopDownComponents,
		enableRoadmapsLicensing,
		enableIdeaplaceLicensing,
		enableOrgHierarchy: showOrgHierarchyComponents,
		enableUpBranchCode,
	} = appContext.featureFlags;

	const [isSaving, setSaving] = useState(false);
	const [formValid, setFormValid] = useState(false);

	const [licensesByApp, setLicensesByApp] = useState<
		Map<ApplicationName, TenantLicenseInfo>
	>(new Map());
	const [licenseDetailUpdates, setLicenseDetailUpdates] = useState<
		LicenseDetailExpanded[]
	>([]);
	const [licenseChangesMade, setLicenseChangesMade] = useState(false);
	const [showLicenses, setShowLicenses] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [ideaplaceChecked, setIdeaplaceChecked] = useState(false);
	const [showIdeaplaceConfirmationModal, setShowIdeaplaceConfirmationModal] =
		useState(false);
	const [roadmapsStartingZero, setRoadmapsStartingZero] = useState(false);
	let ideaplaceToBeRemoved = false;
	const [
		showRoadmapsZeroConfirmationModal,
		setShowRoadmapsZeroConfirmationModal,
	] = useState(false);

	const [
		showOrgHierachyConfirmationModal,
		setShowOrgHierachyConfirmationModal,
	] = useState(false);

	const availableOrgTags =
		orgTagsContext.availableOrgTags.map(createOrgTagOption);

	const selectedOrgTags = organization.orgTags
		?.map((name) => orgTagsContext.availableOrgTagsByName.get(name))
		.map((orgTag) => {
			return {
				value: orgTag?.id,
				label: orgTag?.label,
			} as ComboboxOption;
		})
		.filter(Boolean);

	const orgForm = useForm({
		initialValues: {
			title: organization.title,
			topDownUserManagementEnabled:
				organization.topDownUserManagementEnabled,
			uofpBranchCode: organization.uofpBranchCode,
			orgTags: selectedOrgTags,
		},
		validateInputOnBlur: true,
		validate: {
			title: (val) =>
				!val ? intl.formatMessage(formMessages.requiredField) : null,
		},
	});

	const orgHierarchyForm = OrgHierarchyForm();

	const { title, topDownUserManagementEnabled, uofpBranchCode, orgTags } =
		orgForm.values;
	const { orgStructure, orgParent } = orgHierarchyForm.values;

	useEffect(() => {
		const getCustomerTenantsAndLicenseInfo = async () => {
			const { licensedApplications, licensedTenants, licenseDetails } =
				await requestWithErrorHandling<TenantLicenseInfoResponseDto>({
					method: 'get',
					url: `/io/v1/admin/license/tenantLicenseInfo?customerId=${id}`,
					intl,
					appContext,
				});

			licenseDetails.forEach(
				({ envSelector, licenseType, totalLicenses }) => {
					licenseDetailUpdates.push({
						application: envSelector.application,
						tenantId: envSelector.tenantId,
						licenseName: licenseType.name,
						totalLicenses: totalLicenses,
					});
					if (
						licenseType.name === 'roadmaps-enterprise' &&
						totalLicenses === 0
					) {
						setRoadmapsStartingZero(true);
					}
				},
			);
			setLicenseDetailUpdates(licenseDetailUpdates);

			licensedApplications.map((applicationLicenseTypesDto) => {
				licensesByApp.set(applicationLicenseTypesDto.applicationName, {
					tenantId: null,
					licenseTypes: applicationLicenseTypesDto.licenseTypes,
				});
			});

			licensedTenants.forEach(
				({ application, tenantId, licenseTypes }) => {
					licensesByApp.set(application, {
						tenantId,
						licenseTypes,
					});
				},
			);
			setLicensesByApp(licensesByApp);
			setIdeaplaceChecked(
				licensesByApp.get('TEAMTAP')?.tenantId !== null,
			);
			setShowLicenses(true);
		};

		if (enableRoadmapsLicensing || enableIdeaplaceLicensing) {
			void getCustomerTenantsAndLicenseInfo();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id, enableRoadmapsLicensing, enableIdeaplaceLicensing]);

	useEffect(() => {
		const orgSettingsValid = title.length > 0;
		const orgHierarchyValid =
			!showOrgHierarchyComponents ||
			orgStructure.value === 'Standalone' ||
			(orgStructure.value === 'Child' && orgParent.value !== '');

		setFormValid(orgSettingsValid && orgHierarchyValid);
	}, [title, orgStructure, orgParent, showOrgHierarchyComponents]);

	const sendUpdateOrgRequest = async () => {
		const sendParentOrgTitle =
			showOrgHierarchyComponents && orgStructure.value === 'Child';

		const updateCustomerDto: UpdateCustomerDto = {
			title,
			topDownUserManagementEnabled,
			parentOrgTitle: sendParentOrgTitle ? orgParent.label : undefined,
			uofpBranchCode,
			orgTags: orgTags?.map((opt) => opt?.value as string),
		};

		const { success } = await requestWithErrorHandling<ApiResponse>({
			method: 'put',
			url: `/io/v1/admin/customer/${id}`,
			dataObj: updateCustomerDto,
			appContext: appContext,
			intl,
			successMessage: messages.editOrgSuccess,
		});

		return success;
	};

	const sendUpdateTotalLicensesRequest = async () => {
		const url = '/io/v1/admin/license/total';

		const dto: UpdateTotalLicensesDto = {
			customerId: id,
			licenseDetailUpdates,
			ideaplaceToBeRemoved,
		};

		const { success, message } = (await post(url, dto)) as ApiResponse;
		if (!success) {
			setErrorMessage(message);
		}
		return success;
	};

	const save = async () => {
		setSaving(true);
		let success = await sendUpdateOrgRequest();
		if (licenseChangesMade) {
			success = success && (await sendUpdateTotalLicensesRequest());
		}

		setSaving(false);
		if (success && onConfirm) {
			onConfirm();
		}
	};

	const attemptingToSetParent = () => {
		return (
			!parentCustomerId &&
			orgStructure.value === 'Child' &&
			orgParent.value !== ''
		);
	};

	const ideaplaceUnchecked = () => {
		return (
			licensesByApp.get('TEAMTAP')?.tenantId !== null &&
			!ideaplaceChecked &&
			enableIdeaplaceLicensing
		);
	};

	const roadmapsSetToZero = () => {
		const roadmapsLicenseDetail = licenseDetailUpdates.find(
			(licenseDetail) =>
				licenseDetail.licenseName === 'roadmaps-enterprise',
		);
		return (
			licenseChangesMade &&
			roadmapsLicenseDetail?.totalLicenses === 0 &&
			!roadmapsStartingZero
		);
	};

	const saveCheck = () => {
		if (attemptingToSetParent()) {
			// standalone org changed to child org
			setShowOrgHierachyConfirmationModal(true);
		} else if (ideaplaceUnchecked()) {
			// ideaplace was toggled off
			setShowIdeaplaceConfirmationModal(true);
		} else if (roadmapsSetToZero()) {
			// roadmaps was set to zero
			setShowRoadmapsZeroConfirmationModal(true);
		} else {
			void save();
		}
	};

	return (
		<Modal
			id="edit-organization-dialog"
			onConfirm={saveCheck}
			onCancel={onCancel}
			confirmText={intl.formatMessage(messages.editButton)}
			cancelText={intl.formatMessage(messages.cancelButton)}
			headerText={intl.formatMessage(messages.editHeader)}
			disableConfirm={isSaving || !formValid}
			{...preventEventBubbling}
		>
			<Form label="Edit organization form">
				<SectionHeader>
					<Input
						label={intl.formatMessage(messages.organizationTitle)}
						id={'organization-name'}
						withAsterisk={true}
						{...orgForm.getInputProps('title')}
					/>
				</SectionHeader>

				{showOrgHierarchyComponents ? (
					<OrgHierarchySection
						orgId={id}
						orgForm={orgHierarchyForm}
						parentCustomerId={parentCustomerId}
						parentCustomerTitle={parentCustomerTitle}
						showOrgHierarchyComponents={showOrgHierarchyComponents}
					/>
				) : null}

				<SectionHeader>
					<Input
						label={intl.formatMessage(messages.regRegion)}
						value={regulatoryRegion}
						withAsterisk={true}
						disabled={true}
					/>
				</SectionHeader>

				{enableUpBranchCode && (
					<SectionHeader>
						<Input
							label={intl.formatMessage(messages.branchCodeLabel)}
							id="uofp-branch-code"
							maxLength={50}
							{...orgForm.getInputProps('uofpBranchCode')}
						/>
					</SectionHeader>
				)}

				<SectionHeader>
					<Combobox
						label={intl.formatMessage(messages.orgTagLabel)}
						id="org-tags"
						options={availableOrgTags}
						clearable={true}
						withAsterisk={false}
						multiSelectable={true}
						{...orgForm.getInputProps('orgTags')}
					/>
				</SectionHeader>

				{showTopDownComponents && (
					<TDUMSection>
						<FormattedMessage
							{...messages.topDownUserManagementHeader}
						/>
						<div>
							<Checkbox
								id="editOrg-TDUM-enabled"
								label={intl.formatMessage(
									messages.topDownUserManagementCheckBox,
								)}
								{...orgForm.getInputProps(
									'topDownUserManagementEnabled',
									{
										type: 'checkbox',
									},
								)}
								selected={topDownUserManagementEnabled}
							/>
						</div>
						<WarningDiv>
							<CautionLabel>
								<LeftAlignedWarningIcon />
								<FormattedMessage {...messages.cautionText} />
							</CautionLabel>
							<StyledDiv>
								<FormattedMessage
									{...messages.topDownUserManagementWarningMessage}
								/>
							</StyledDiv>
						</WarningDiv>
					</TDUMSection>
				)}
			</Form>

			{showLicenses &&
			(enableIdeaplaceLicensing || enableRoadmapsLicensing) ? (
				<LicensesSection
					licenseDetailUpdates={licenseDetailUpdates}
					setLicenseDetailUpdates={setLicenseDetailUpdates}
					totalLicensesByApp={licensesByApp}
					ideaplaceChecked={ideaplaceChecked}
					setIdeaplaceChecked={setIdeaplaceChecked}
					setLicenseChangesMade={setLicenseChangesMade}
					errorMessage={errorMessage}
					enableRoadmapsLicensing={enableRoadmapsLicensing}
					enableIdeaplaceLicensing={enableIdeaplaceLicensing}
				/>
			) : null}

			{showOrgHierachyConfirmationModal && (
				<OrgHierarchyConfirmationModal
					onConfirm={() => {
						if (ideaplaceUnchecked()) {
							setShowIdeaplaceConfirmationModal(true);
							setShowOrgHierachyConfirmationModal(false);
						} else if (roadmapsSetToZero()) {
							setShowRoadmapsZeroConfirmationModal(true);
							setShowOrgHierachyConfirmationModal(false);
						} else {
							void save();
							setShowOrgHierachyConfirmationModal(false);
						}
					}}
					onCancel={() => {
						setShowOrgHierachyConfirmationModal(false);
					}}
				/>
			)}

			{showIdeaplaceConfirmationModal && (
				<IdeaPlaceConfirmationModal
					onConfirm={() => {
						ideaplaceToBeRemoved = true;
						if (roadmapsSetToZero()) {
							setShowRoadmapsZeroConfirmationModal(true);
							setShowIdeaplaceConfirmationModal(false);
						} else {
							void save();
							setShowIdeaplaceConfirmationModal(false);
						}
					}}
					onCancel={() => {
						setShowIdeaplaceConfirmationModal(false);
					}}
				/>
			)}

			{showRoadmapsZeroConfirmationModal && (
				<RoadmapsZeroConfirmationModal
					onConfirm={() => {
						void save();
						setShowRoadmapsZeroConfirmationModal(false);
					}}
					onCancel={() => {
						setShowRoadmapsZeroConfirmationModal(false);
					}}
				/>
			)}
		</Modal>
	);
};

// Wrapper that decides which version of edit org dialog to show
const EditOrganizationDialogWrapper = (props: EditOrganizationDialogProps) => {
	const { organization } = props;
	if (organization.parent) {
		return <EditParentOrganizationDialog {...props} />;
	} else {
		return <EditOrganizationDialog {...props} />;
	}
};
export default EditOrganizationDialogWrapper;
