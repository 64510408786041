import React, { useContext } from 'react';
import { IntlShape, MessageDescriptor, useIntl } from 'react-intl';
import {
	AppContext,
	AppContextProps,
	CustomerContext,
} from '../../../../context';
import messages from '../UsersPage.messages';
import { requestWithErrorHandling } from '../../../../hooks/request/request';
import { getUserIds } from './userUtils';
import { Key } from '@planview/pv-icons';
import { User } from '../../../../types';

interface UserWithAuthType extends User {
	authenticationType: string;
}

const makeRequest = async ({
	users,
	customerId,
	enablePasswordLogin,
	intl,
	appContext,
	refresh,
	successMessage,
	successMessagePlural,
}: {
	users: User[];
	customerId: string;
	enablePasswordLogin: boolean;
	intl: IntlShape;
	appContext: AppContextProps;
	refresh: () => void;
	successMessage: MessageDescriptor;
	successMessagePlural: MessageDescriptor;
}) => {
	const userIds = getUserIds(users);
	const successMessageString = intl.formatMessage(
		userIds.length === 1 ? successMessage : successMessagePlural,
		{
			count: userIds.length,
		},
	);

	await requestWithErrorHandling({
		method: 'post',
		url: `/io/v1/user/changePasswordLoginEnabled/${enablePasswordLogin}`,
		dataObj: { userIds, customerId },
		appContext,
		intl,
		successMessage: successMessageString,
	});
	refresh();
};

const isEnabled = (
	users: UserWithAuthType[],
	authType: string,
	isCustomerCare: boolean,
): boolean => {
	return (
		users.length > 0 &&
		users.every((user) => {
			if (user.customerSsoEnabled) {
				return user.authenticationType === authType;
			}

			if (isCustomerCare) {
				return authType === 'SSO'
					? !user.localAuthOverrideEnabled
					: user.localAuthOverrideEnabled;
			}

			return false;
		})
	);
};

const useChangePasswordLoginActions = ({
	refresh,
	isCustomerCare,
}: {
	refresh: () => void;
	isCustomerCare: boolean;
}) => {
	const intl = useIntl();
	const appContext = useContext(AppContext);
	const customerContext = useContext(CustomerContext);
	const requestArgs = {
		intl,
		appContext,
		refresh,
		customerId: customerContext.customer.id,
	};

	return {
		enablePasswordLoginAction: {
			icon: <Key />,
			message: messages.enablePasswordLogin,
			isEnabled: (users: UserWithAuthType[]) =>
				isEnabled(users, 'SSO', isCustomerCare),
			isHidden: (users: UserWithAuthType[]) =>
				!isEnabled(users, 'SSO', isCustomerCare),
			activateFn: (users: User[]) =>
				makeRequest({
					users,
					enablePasswordLogin: true,
					successMessage: messages.enablePasswordLoginSuccess,
					successMessagePlural:
						messages.enablePasswordLoginSuccessPlural,
					...requestArgs,
				}),
		},
		disablePasswordLoginAction: {
			icon: <Key />,
			message: messages.disablePasswordLogin,
			isEnabled: (users: UserWithAuthType[]) =>
				isEnabled(users, 'PASSWORD', isCustomerCare),
			isHidden: (users: UserWithAuthType[]) =>
				!isEnabled(users, 'PASSWORD', isCustomerCare),
			activateFn: (users: User[]) =>
				makeRequest({
					users,
					enablePasswordLogin: false,
					successMessage: messages.disablePasswordLoginSuccess,
					successMessagePlural:
						messages.disablePasswordLoginSuccessPlural,
					...requestArgs,
				}),
		},
	};
};

export default useChangePasswordLoginActions;
