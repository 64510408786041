import { defineMessages } from 'react-intl';

const messages = defineMessages({
	addAttribute: {
		id: 'admin.settings.advanced.addAttribute.button',
		description:
			'Add Attribute button in Additional SAML Attributes section',
		defaultMessage: 'Add attribute',
	},
	additionalAttributesHeader: {
		id: 'admin.settings.advanced.additionalAttributes.header',
		description: 'Additional SAML Attributes header',
		defaultMessage: 'Additional attributes',
	},
	friendlyNameHeader: {
		id: 'admin.settings.advanced.header.friendlyName',
		description:
			'Header for required Friendly Name input field in Additional SAML attributes section',
		defaultMessage: 'Friendly name',
	},
	nameHeader: {
		id: 'admin.settings.advanced.header.name',
		description:
			'Header for required Name input field in Additional SAML attributes section',
		defaultMessage: 'Name',
	},
	nameFormatHeader: {
		id: 'admin.settings.advanced.header.nameFormat',
		description:
			'Header for required Name Format input field in Additional SAML attributes section',
		defaultMessage: 'Name format',
	},
	requiredHeader: {
		id: 'admin.settings.advanced.header.required',
		description:
			'Header for required checkbox in Additional SAML attributes section',
		defaultMessage: 'Required',
	},
	samlUsernameAttribute: {
		id: 'admin.settings.advanced.samlUsernameAttribute',
		description:
			'Label for SAML username attribute (config in the XML used for lookup)',
		defaultMessage: 'Username attribute (Optional)',
	},
	selectSigningAlgorithm: {
		id: 'admin.settings.advanced.selectSigningAlgorithm',
		description: 'Label for drop down to choose a SAML signing algorithm',
		defaultMessage: 'Signing algorithm',
	},
	selectNameIDPolicyFormat: {
		id: 'admin.settings.advanced.selectNameIDPolicyFormat',
		description:
			'Label for drop down to choose a SAML NameID policy format',
		defaultMessage: 'NameID policy format',
	},
	selectLookupTypeField: {
		id: 'admin.settings.advanced.selectLookupTypeField',
		description: 'Label for drop down to choose a SAML lookup type',
		defaultMessage: 'NameID lookup type',
	},
	lookupTypeOptionEmailAddress: {
		id: 'admin.settings.advanced.lookupTypeOptionEmailAddress',
		description: 'Email address option for lookup type drop down',
		defaultMessage: 'Email address',
	},
	lookupTypeOptionLoginAlias: {
		id: 'admin.settings.advanced.lookupTypeOptionLoginAlias',
		description: 'Login alias option for lookup type drop down',
		defaultMessage: 'Login alias',
	},
	lookupTypeOptionUsernameFromEmail: {
		id: 'admin.settings.advanced.lookupTypeOptionUsernameFromEmail',
		description:
			'Username from email address option for lookup type drop down',
		defaultMessage: 'Username from email',
	},
	helpTooltipsamlUsernameAttribute: {
		id: 'admin.settings.advanced.helpTooltipsamlUsernameAttribute',
		description: 'Help tooltip for username attribute field',
		defaultMessage:
			'Enter an attribute name here. By default, Planview Admin uses the SAML assertion’s NameID attribute for the user’s email or login alias.',
	},
	helpTooltipSamlSigningAlgorithm: {
		id: 'admin.settings.advanced.helpTooltipSamlSigningAlgorithm',
		description: 'Help tooltip for SAML signing algorithm dropdown',
		defaultMessage:
			'Select the algorithm used to sign SAML authentication requests.',
	},
	helpTooltipSamlNameIDLookupType: {
		id: 'admin.settings.advanced.helpTooltipSamlNameIDLookupType',
		description: 'Help tooltip for SAML NameID lookup type dropdown',
		defaultMessage: 'Select how users will be identified.',
	},
	helpTooltipSamlNameIDFormat: {
		id: 'admin.settings.advanced.helpTooltipSamlNameIDFormat',
		description: 'Help tooltip for SAML NameID policy format dropdown',
		defaultMessage:
			'The format of the NameID property in the SAML assertion.',
	},
	helpTooltipAdditionalAttributes: {
		id: 'admin.settings.advanced.helpTooltipAdditionalAttributes',
		description: 'Help tooltip for additional SAML attributes',
		defaultMessage:
			'Additional SAML attributes convey user information from the SSO provider to other Planview products such as EA.',
	},
	helpTooltipAdditionalAttributesName: {
		id: 'admin.settings.advanced.helpTooltipAdditionalAttributesName',
		description: 'Help tooltip for name field',
		defaultMessage: 'Name of the attribute in the SAML assertion.',
	},
	helpTooltipAdditionalAttributesNameFormat: {
		id: 'admin.settings.advanced.helpTooltipAdditionalAttributesFormat',
		description: 'Help tooltip for name format field',
		defaultMessage: 'Format of the attribute in the SAML assertion.',
	},
	helpTooltipAdditionalAttributesFriendlyName: {
		id: 'admin.settings.advanced.helpTooltipAdditionalAttributesFriendlyName',
		description: 'Help tooltip for friendly name field',
		defaultMessage: "A more human-readable form of the attribute's name.",
	},
});

export default messages;
