import styled from 'styled-components';
import { Warning } from '@planview/pv-icons';
import { color, spacingPx, text } from '@planview/pv-utilities';

export type option = {
	value: string;
	label: string;
};

export type modalProps = {
	onConfirm: () => void;
	onCancel: () => void;
};

export const SectionHeader = styled.div`
	${text.regular}
	font-weight: 600;
`;

export const TDUMSection = styled.div`
	${text.regular}
	padding: ${spacingPx.small} 0;
	font-weight: 600;
`;

export const ModalText = styled.div`
	${text.regular}
	margin-top: ${spacingPx.xsmall};
	padding-bottom: ${spacingPx.small};
`;

export const LeftAlignedWarningIcon = styled(Warning)`
	width: fit-content;
	padding-right: ${spacingPx.xsmall};
`;

export const WarningDiv = styled.div`
	color: ${color.iconWarning};
	padding: 0 0 ${spacingPx.small};
	font-weight: 400;
`;

export const CautionLabel = styled.div`
	display: flex;
	align-items: center;
	height: ${spacingPx.medium};
	font-weight: 600;
`;
