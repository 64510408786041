import { align, color, shadow, spacingPx } from '@planview/pv-utilities';
import styled from 'styled-components';

export const Toolbar = styled.div`
	${align.centerV}
	padding: ${spacingPx.small};
	gap: 0 ${spacingPx.small};
	${shadow.small};
	background: ${color.gray50};
	z-index: 10;
`;

/**
 * A fill between left and right aligned items on a toolbar
 */
export const TbFill = styled.div`
	flex: auto;
	width: 1px;
`;
