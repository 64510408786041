import { defineMessages } from 'react-intl';

const messages = defineMessages({
	copyLink: {
		id: 'home.overview.copyLink',
		description: 'Menu text for the option to copy a link to a product',
		defaultMessage: 'Copy link',
	},
	copyToClipboardSuccess: {
		id: 'home.overview.copyToClipboardSuccess',
		description: 'Success message after copying to clipboard',
		defaultMessage: 'Product link copied to clipboard',
	},
	copyToClipboardError: {
		id: 'home.overview.copyToClipboardError',
		description: 'Error message trying to copy to clipboard',
		defaultMessage: 'Could not copy product link to clipboard',
	},
	pageTitle: {
		id: 'home.overview.pageTitle',
		description: 'Header text of the home overview tab',
		defaultMessage: 'Overview',
	},
	products: {
		id: 'home.overview.section.products',
		description: 'Header text of the home overview products section',
		defaultMessage: 'Products',
	},
	manageProducts: {
		id: 'home.overview.section.manageProducts',
		description: 'Link to manage products page',
		defaultMessage: 'Manage Products',
	},
	noProducts: {
		id: 'home.overview.section.noProducts',
		description:
			'Display text when no products are available, for an end-user',
		defaultMessage:
			'Configuration not complete. Contact your Planview Administrator.',
	},
	noProductsAdmin: {
		id: 'home.overview.section.admin.noProducts',
		description:
			'Display text when no products are available, for an admin user that has permission to connect to products',
		defaultMessage:
			'Add new products, connect existing product accounts, or show hidden products.',
	},
});

export default messages;
