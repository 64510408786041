/* global pendo */
/* eslint-disable
	@typescript-eslint/no-unsafe-argument,
	@typescript-eslint/no-unsafe-assignment,
	@typescript-eslint/no-unsafe-member-access,
	@typescript-eslint/no-unsafe-call,
	@typescript-eslint/restrict-template-expressions,
	@typescript-eslint/require-await,
	@typescript-eslint/no-floating-promises,
	@typescript-eslint/no-unsafe-return,
	@typescript-eslint/ban-ts-comment
*/
import axios, { AxiosHeaders } from 'axios';
import qs from 'qs';
import {
	getRequestErrorForAxios,
	getRequestErrorForFetch,
} from './RequestError';
import {
	checkForCsrfToken,
	getRequestHeaders,
	setCustomResponseAttributes,
} from './util';
import messages from '../../messages/server';
import { IntlShape, MessageDescriptor } from 'react-intl';
import { AppContextProps, UserContextProps } from '../../context';
import { ApiResponse, BaseResponse, LoginResponse } from '../../types/api/api';
import { ToastType } from '../../types/toast';
import { User } from '../../types';

export const LOGIN_API_URL = '/api/login';
export const LOG_ERROR_API_URL = '/api/log';

const request = async (url: string | URL, options = {}) => {
	try {
		const response = await fetch(url, options);
		const responseData = await response.json();
		// Check if there is a CSRF token sent in the response header. We get one for login get.
		checkForCsrfToken(response);
		return setCustomResponseAttributes(responseData, response);
	} catch (error) {
		return getRequestErrorForFetch(error);
	}
};

export const get = async <TReturnType>(url: string, options = {}) => {
	try {
		const response = await request(url, {
			method: 'get',
			...options,
		});
		if (!response) {
			return {
				success: false,
			} as TReturnType & BaseResponse;
		}
		return response as TReturnType & BaseResponse;
	} catch (err) {
		console.warn('Request caught exception', err);
		return {
			success: false,
		} as TReturnType & BaseResponse;
	}
};

export const post = async (
	url: string,
	dataObj: object | undefined,
	asForm = false,
	additionalHeaders = {},
) => {
	try {
		const headers = {
			...getRequestHeaders(),
			...additionalHeaders,
		} as AxiosHeaders;
		const data = asForm
			? qs.stringify(dataObj, { arrayFormat: 'repeat' })
			: dataObj;
		const response = await axios.post(url, data, {
			headers,
		});
		// Check if there is a CSRF token sent in the response header. We get one for login post.
		checkForCsrfToken(response);
		return setCustomResponseAttributes(response.data, response);
	} catch (error) {
		return getRequestErrorForAxios(error, url);
	}
};

let lastError: string | null = null;

/**
 * Logs a message to the server.
 *
 * @param msg message to log
 * @param err error object to log | Note: we have to except `any` type here because the PromiseRejectionEvent reason
 *            property accepts any argument.
 *            See https://github.com/Microsoft/TypeScript/blob/v2.8.3/src/lib/es2015.promise.d.ts#L175-L187
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const logError = async (msg: string, err: any = null) => {
	try {
		const message = err?.message ? `${msg}: ${err.message}` : msg;
		// eslint-disable-next-line no-console
		console.error(message, err);

		// simple debounce as we are logging same thing twice sometimes
		if (lastError !== message) {
			lastError = message;
			const data = {
				message,
				location: window.location.pathname,
				stack: err?.stack,
				level: 'error', // valid values are from log4j: "error", "info", "debug"
			};
			post(LOG_ERROR_API_URL, data);
		}
	} catch (err2) {
		// eslint-disable-next-line no-console
		console.error('Error handler: ', err2);
	}
};

export const put = async (url: string, dataObj: object | undefined) => {
	try {
		const response = await axios.put(url, dataObj, {
			headers: getRequestHeaders() as AxiosHeaders,
		});
		return setCustomResponseAttributes(response.data, response);
	} catch (error) {
		return getRequestErrorForAxios(error, url);
	}
};

export const del = async (url: string, options = {}) => {
	try {
		const response = await axios.delete(url, {
			headers: getRequestHeaders() as AxiosHeaders,
			...options,
		});
		return setCustomResponseAttributes(response.data, response);
	} catch (error) {
		return getRequestErrorForAxios(error, url);
	}
};

export const head = async (url: string, dataObj?: object) => {
	try {
		const response = await axios.head(url, dataObj);
		const data = typeof response.data === 'object' ? response.data : {};
		return setCustomResponseAttributes(data, response);
	} catch (error) {
		return getRequestErrorForAxios(error, url);
	}
};

let pendoInitialized = false;
const initializePendo = (response: LoginResponse) => {
	if (!pendoInitialized && response.featureFlags.enablePendo) {
		try {
			// @ts-ignore
			pendo.initialize({
				visitor: {
					id: response.user.id,
					isAdmin: response.user.isAdmin,
					isCustomerCare: response.user.isCustomerCare,
					isSuperAdmin: response.user.isSuperAdmin,
				},
				account: {
					id: response.customer.id,
					name: response.customer.title,
				},
				excludeAllText: true,
			});
		} catch (error) {
			//pendo not defined
			console.warn('There was an error initializing Pendo', error);
		}
		pendoInitialized = true;
	}
};

const postLogin = (
	userContext: UserContextProps,
	appContext: AppContextProps,
	response: LoginResponse,
) => {
	if (!response) {
		return;
	}
	const { success, _appVersion } = response;
	if (success) {
		userContext.processUserData(response);
		appContext.processAppData(response);
		initializePendo(response);
	}

	// Check if there is a new version of the app available.
	appContext.checkAppVersion(_appVersion);
	appContext.scheduleRefreshAppData();

	return { ...response };
};

/**
 * Login in to the app and set the appropriate context attributes
 * @param user - object containing username and password
 * @param userContext - User Context
 * @param appContext - App Context
 */
export const login = async (
	user: User,
	userContext: UserContextProps,
	appContext: AppContextProps,
) => {
	return post(LOGIN_API_URL, user, true).then(
		// @ts-ignore
		postLogin.bind(null, userContext, appContext),
	);
};

/**
 * Get Active session data and set the appropriate context attributes
 * @param userContext - User Context
 * @param appContext - App Context
 */
export const getActiveSession = async (
	userContext: UserContextProps,
	appContext: AppContextProps,
) => {
	return get(LOGIN_API_URL).then(
		// @ts-ignore
		postLogin.bind(null, userContext, appContext),
	);
};

/**
 * Converts the string method name to the actual method in this class
 * @param method - action GET, PUT, POST, DELETE
 * @return Request method
 */
const getRequestMethod = (method: string) => {
	let returnMethod;
	switch (method.toLocaleLowerCase()) {
		case 'get':
			returnMethod = get;
			break;
		case 'put':
			returnMethod = put;
			break;
		case 'post':
			returnMethod = post;
			break;
		case 'delete':
			returnMethod = del;
			break;
		default:
			returnMethod = get;
			break;
	}
	return returnMethod;
};

const showToastOnResponse = ({
	intl,
	appContext,
	successMessage,
	infoMessage,
	response,
}: {
	intl: IntlShape;
	appContext: AppContextProps;
	successMessage?: string | MessageDescriptor | null;
	infoMessage?: string | MessageDescriptor | null;
	response: ApiResponse;
}) => {
	const { showToast, setNeedsLogin } = appContext;
	const { success, message } = response;

	if (!success) {
		if (response.httpStatusCode === 401) {
			setNeedsLogin(true);
		} else {
			showToast &&
				showToast({
					message: intl.formatMessage(
						message ? { id: message } : messages.unexpectedError,
					),
					type: ToastType.DANGER,
				});
		}
		return;
	}

	// No toast is shown if success or info messages are not passed.
	if (!successMessage && !infoMessage && !message) {
		return;
	}

	// Show success or info toast depending on the message param passed
	let msg = successMessage || infoMessage || message;
	if (typeof msg !== 'string') {
		msg = intl.formatMessage(msg);
	}
	showToast &&
		showToast({
			message: msg,
			type:
				successMessage || success ? ToastType.SUCCESS : ToastType.INFO,
		});
};

export type RequestWithErrorHandlingProps = {
	/**
	 * action GET, PUT, POST, DELETE
	 */
	method: string;

	/**
	 * URL used for the request
	 */
	url: string;

	/**
	 * Data to include in the request body
	 */
	dataObj?: object;

	/**
	 * AppContext
	 */
	appContext: AppContextProps;

	/**
	 * React Intl object
	 */
	intl: IntlShape;

	/**
	 * Message Object (including id and defaultMessages) to show for a successful request
	 * Either successMessage or infoMessage is required. The icon for the toast depends on the param passed.
	 */
	successMessage?: string | MessageDescriptor;

	/**
	 * Message Object (including id and defaultMessages) to show for a successful request
	 * Either successMessage or infoMessage is required. The icon for the toast depends on the param passed.
	 */
	infoMessage?: string | MessageDescriptor;
};

/**
 * Post an AJAX request and handle any default error messages and show any successful status messages.
 * @param props - the props for the request
 * @return request promise
 */
export const requestWithErrorHandling = async <TReturnType>(
	props: RequestWithErrorHandlingProps,
) => {
	const {
		method,
		url,
		dataObj,
		appContext,
		intl,
		successMessage,
		infoMessage,
	} = props;
	const requestMethod = getRequestMethod(method);
	const requestResponse = (await requestMethod(url, dataObj)) as ApiResponse &
		TReturnType;
	const { _appVersion } = requestResponse;
	const { checkAppVersion } = appContext;

	// Show toast message as needed
	showToastOnResponse({
		intl,
		appContext,
		successMessage,
		infoMessage,
		response: requestResponse,
	});

	// Check if there is a new version of the app available.
	checkAppVersion && checkAppVersion(_appVersion);

	return requestResponse as TReturnType & BaseResponse;
};

export const downloadUrl = async ({
	url,
	fileName,
}: {
	url: string;
	fileName: string;
}) => {
	const response = await axios.get(url, {
		responseType: 'blob',
	});

	const linkUrl = window.URL.createObjectURL(response.data);
	const link = document.createElement('a');
	link.href = linkUrl;
	link.setAttribute('download', fileName);
	document.body.appendChild(link);
	link.click();
};
