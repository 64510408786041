import { useContext, useEffect, useRef } from 'react';
import { requestWithErrorHandling } from './request/request';
import { AppContext } from '../context';
import { useIntl } from 'react-intl';

/**
 * React hook to poll a url
 *
 * @param {String} url url to poll
 * @param {Function} callback callback function
 * @param {Number} delay delay between polls in ms
 * @param {Object} appContext the application context
 * @param {Object} intl the react intl package
 * @param {boolean} enabled whether or not polling is enabled
 * @return {void} nothing
 */
function usePolling({ url, callback, delay = 10_000, enabled = true }) {
	const savedCallback = useRef();
	const savedUrl = useRef();
	const enabledRef = useRef();
	const appContext = useContext(AppContext);
	const intl = useIntl();
	const { idle } = appContext;

	// Remember the latest variables
	useEffect(() => {
		savedCallback.current = callback;
		enabledRef.current = enabled;
	}, [callback, enabled]);

	// Set up the interval - this effect only runs on change to delay
	useEffect(() => {
		savedUrl.current = url;

		let id = null;
		async function tick() {
			const urlForRequest = savedUrl.current;
			const response = await requestWithErrorHandling({
				method: 'get',
				url: urlForRequest,
				appContext,
				intl,
			});
			const { success } = response;

			if (!success) {
				enabledRef.current = false;
				return;
			}

			// Only use callback if the url still matches the url being requested,
			// and if this hook is enabled
			if (urlForRequest === savedUrl.current && enabledRef.current) {
				savedCallback.current(response);
				if (!idle) {
					id = setTimeout(tick, delay);
				}
			}
		}

		if (enabledRef.current && !idle) {
			tick();
		}
		return () => {
			clearTimeout(id);
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [delay, url, idle]);
}

export default usePolling;
