import React from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';
import {
	ButtonPrimary as UIButtonPrimary,
	ButtonGhost as UIButtonGhost,
	ButtonEmpty as UIButtonEmpty,
} from '@planview/pv-uikit';
import { sizePx, spacingPx } from '@planview/pv-utilities';

const StyledButton = styled(UIButtonPrimary)`
	${(props) => {
		return (
			props.fluid &&
			css`
				font-weight: 900;
				height: ${sizePx.medium};
				margin-bottom: ${spacingPx.medium};
				width: 100%;
			`
		);
	}}
`;

const getButtonText = (message, intl) => {
	return typeof message === 'object' ? intl.formatMessage(message) : message;
};

// BUTTONPRIMARY
export const ButtonPrimary = ({ message, ariaLabel = null, ...rest }) => {
	const text = getButtonText(message, useIntl());
	return (
		<StyledButton aria-label={ariaLabel || text} {...rest}>
			{text}
		</StyledButton>
	);
};

// BUTTONEMPTY
export const ButtonEmpty = ({ message, ariaLabel = null, ...rest }) => {
	const text = getButtonText(message, useIntl());
	return (
		<StyledButton
			as={UIButtonEmpty}
			aria-label={ariaLabel || text}
			{...rest}
		>
			{text}
		</StyledButton>
	);
};

// BUTTONGHOST
const StyledButtonGhost = styled(StyledButton)`
	border-width: 2px;

	&:hover {
		border-width: 2px;
	}
`;

export const ButtonGhost = ({ message, ...rest }) => {
	const text = getButtonText(message, useIntl());
	const GhostButton = rest.fluid ? StyledButtonGhost : StyledButton;
	return (
		<GhostButton as={UIButtonGhost} aria-label={text} {...rest}>
			{text}
		</GhostButton>
	);
};

/*
 * Button with an icon and message, such as a settings icon on Settings Page > Services > OKR settings
 * For icons see @planview/pv-icons
 */
export const IconButton = ({ icon, message, ...rest }) => {
	const intl = useIntl();
	const text = intl.formatMessage(message);
	return (
		<UIButtonGhost icon={icon} aria-label={text} {...rest}>
			{text}
		</UIButtonGhost>
	);
};
