import { defineMessages } from 'react-intl';

const messages = defineMessages({
	production: {
		id: 'global.production',
		description: 'Label used for the Production environments',
		defaultMessage: 'Production',
	},
	sandbox: {
		id: 'global.sandbox',
		description: 'Label used for the Sandbox environments',
		defaultMessage: 'Sandbox',
	},
	primarySandbox: {
		id: 'global.sandbox.primary',
		description: 'Label used for primary sandbox environments',
		defaultMessage: 'Sandbox (primary)',
	},
});

export default messages;
