/**
 * Centralized place for localized server messages displayed on the client. The server ideally should send the 'id'
 * of the message to the client, along with a default message (English) for fallback.
 * The server devs should be adding strings that needs to be localized here, and send back the message ids in the
 * error response.
 */
import { defineMessages } from 'react-intl';

const messages = defineMessages({
	authenticationError: {
		id: 'server.error.login.authentication',
		defaultMessage: 'Invalid credentials. Please try again.',
		description:
			'Message shown when a username/password combination does not result in successful sign in',
	},
	invalidSession: {
		id: 'server.error.login.session.invalid',
		defaultMessage: 'Sign in required.',
		description: 'Session has expired or is invalid',
	},
	userLogout: {
		id: 'server.message.login.logout',
		defaultMessage: 'Sign out successful.',
		description: 'User signed out success',
	},
	signInText: {
		id: 'server.login.signInText',
		defaultMessage: 'Signing in to {0}...',
		description:
			'Message shown when clicking a tile/link during the sign in to the application. {0} = the app name e.g. Portfolios, AgilePlace etc',
	},
	passwordChange: {
		id: 'server.message.password.change',
		defaultMessage: 'User password change successful.',
		description: 'Password change success',
	},
	passwordChangeError: {
		id: 'server.error.password.change.fail',
		defaultMessage: 'User password change failed.',
		description: 'Password change fail',
	},
	customerAlreadyExistsBoth: {
		id: 'server.message.customer.already.exists.both',
		defaultMessage:
			'A customer with this title, and a user with the given email address, already exists.',
		description: 'Customer and user already exists, unable to create',
	},
	customerAlreadyExistsCustomer: {
		id: 'server.message.customer.already.exists.customer',
		defaultMessage: 'A customer with this title already exists.',
		description: 'Customer already exists, unable to create',
	},
	customerCreateError: {
		id: 'server.message.customer.create.failure',
		defaultMessage: 'Error attempting to create customer.',
		description: 'Error attempting to create customer',
	},
	customerMissingError: {
		id: 'server.message.customer.not.found',
		defaultMessage: 'Customer not found',
		description: 'Customer missing from request or database',
	},
	passwordChangeValidationError: {
		id: 'server.error.password.change.fail.validation',
		defaultMessage: 'Password must be at least 12 characters in length',
		description: 'Password change failed due to validation rules',
	},
	passwordChangeTokenError: {
		id: 'server.error.password.change.fail.token.expired',
		defaultMessage:
			'Token expired, please redo the password change process',
		description: 'Password change failed due to expired token',
	},
	emailMismatch: {
		id: 'server.error.email.mismatch',
		defaultMessage: 'Emails do not match.',
		description: 'Email submitted does not match for this user',
	},
	applicationAccessDenied: {
		id: 'server.message.app.access.denied',
		defaultMessage:
			'You do not have access to this {0} instance. Please contact your administrator to grant you access.',
		description:
			'Message when a user is not granted access to a product. {0} = Product name',
	},
	foundationApplicationAccessDenied: {
		id: 'server.message.foundation.app.access.denied',
		defaultMessage:
			'You do not have access to {0}. Please contact your administrator to grant you access.',
		description:
			"Message when a user is not granted access to a product, for products that aren't instances. {0} = Product name",
	},
	applicationNotActivated: {
		id: 'server.error.app.applicationNotActivated',
		defaultMessage:
			'This {0} instance is not activated for SSO. Please contact your administrator.',
		description:
			'Message during sign in if product is not activated for SSO. {0} = Product name',
	},
	applicationUrlInvalid: {
		id: 'server.error.app.applicationUrlInvalid',
		defaultMessage: 'URL could not be determined for {0}.',
		description:
			'Message during sign in if product has an invalud URL. {0} = Product environment identifier',
	},
	userAlreadyExists: {
		id: 'server.message.user.already.exists',
		defaultMessage: 'A user with email address "{0}" already exists.',
		description:
			'Error message when a user cannot be created or updated due to an already existing user. {0} = the email address in use',
	},
	userWithUsernameAlreadyExists: {
		id: 'server.message.user.username.already.exists',
		defaultMessage: 'A user with username "{0}" already exists.',
		description:
			'Error message when a user cannot be created or updated due to an already existing user of same username. {0} = the username',
	},
	userWithLoginAliasAlreadyExists: {
		id: 'server.message.user.loginAlias.already.exists',
		defaultMessage: 'A user with login alias "{0}" already exists.',
		description:
			'Error message when a user cannot be created or updated due to an already existing user of same login alias. {0} = the login alias',
	},
	impersonateReasonIsRequired: {
		id: 'server.message.user.impersonate.reasonIsRequired',
		defaultMessage:
			'To impersonate another user, a business reason is required.',
		description:
			'A user cannot impersonate another user without providing a business reason',
	},
	notImplemented: {
		id: 'server.message.not.implemented',
		defaultMessage: 'Not implemented.',
		description: 'Not implemented',
	},
	notAllowed: {
		id: 'server.message.not.allowed',
		defaultMessage: 'You are not allowed to perform this action.',
		description: 'User does not have permission to do this',
	},
	impersonatorNotAllowed: {
		id: 'server.message.impersonated.user.not.allowed',
		defaultMessage:
			'You are not allowed this action while impersonating another user.',
		description:
			'You are not allowed this action while impersonating another user',
	},
	userCreateSuccessPassword: {
		id: 'server.message.user.create.success.password',
		defaultMessage:
			'User created. An email has been sent to the user to create a Planview account.',
		description:
			'Success on create new user function when user will sign in with password',
	},
	userCreateSuccessSSO: {
		id: 'server.message.user.create.success.sso',
		defaultMessage: 'User created.',
		description:
			'Success on create new user function when user will sign in with SSO',
	},
	userCreateError: {
		id: 'server.message.user.create.failure',
		defaultMessage: 'Error attempting to create user.',
		description: 'Error attempting to create user',
	},
	userUpdateSuccessInvited: {
		id: 'server.message.user.update.success.invited',
		defaultMessage:
			'User updated. An email has been sent to the user to create a Planview account.',
		description:
			'Success on update user function and user received invite email',
	},
	userUpdateSuccess: {
		id: 'server.message.user.update.success',
		defaultMessage: 'User updated.',
		description: 'Success on update user function',
	},
	userUpdateError: {
		id: 'server.message.user.update.failure',
		defaultMessage: 'Error attempting to update user.',
		description: 'Error attempting to update user',
	},
	setLocalAuthError: {
		id: 'server.message.user.update.local.auth.failure',
		defaultMessage:
			'Cannot require a password for the user when customer is not configured for SAML auth.',
		description: 'Error to update user when SAML SSO auth not enabled.',
	},
	userUpdateAdminError: {
		id: 'server.message.user.update.admin.failure',
		defaultMessage: 'You cannot remove your own admin role.',
		description:
			'Error attempting to update user when user is trying to change their own admin role.',
	},
	userDeleteError: {
		id: 'server.message.user.delete.failure',
		defaultMessage: 'Error attempting to delete user.',
		description: 'Error attempting to delete user',
	},
	tenantNotFoundError: {
		id: 'server.message.tenant.not.found',
		defaultMessage: 'Product instance not found.',
		description:
			'Error when a product instance was not found during some operation. Unlikely to show up in the UI unless something is really broken.',
	},
	roleNotFoundError: {
		id: 'server.message.role.not.found',
		defaultMessage: 'Role not found.',
		description:
			'Error when a role was not found during some operation. Unlikely to show up in the UI unless something is really broken.',
	},
	captchaError: {
		id: 'server.error.password.change.fail.captcha',
		defaultMessage: 'CAPTCHA did not match',
		description: 'Captcha did not match',
	},
	unexpectedError: {
		id: 'server.error.unexpectedError',
		defaultMessage: 'An unexpected error has occurred. Please try again.',
		description: 'Catch for no server response at all',
	},
	userPromoteError: {
		id: 'server.userAdmin.promote.failure',
		defaultMessage:
			'This user could not be made an administrator. Please contact customer support.',
		description: 'Error on make admin function',
	},
	userPromoteSelfError: {
		id: 'server.userAdmin.demote.self.failure',
		defaultMessage: 'Cannot remove administrator on yourself.',
		description:
			'Error on remove admin function when a user tries to remove on self',
	},
	userIgnore: {
		id: 'server.tenantAdmin.ignore.success',
		defaultMessage: 'User ignored.',
		description: 'Success on ignore user function',
	},
	userIgnoreError: {
		id: 'server.tenantAdmin.ignore.failure',
		defaultMessage:
			'We were unable to ignore the user.  Please contact customer support.',
		description: 'Error on ignore user function',
	},
	userUnignore: {
		id: 'server.tenantAdmin.unignore.success',
		defaultMessage: 'User unignored.',
		description: 'Success on unignore user function',
	},
	userUnignoreError: {
		id: 'server.tenantAdmin.unignore.failure',
		defaultMessage:
			'We were unable to unignore the user.  Please contact customer support.',
		description: 'Error on unignored user function',
	},
	userUnmap: {
		id: 'server.tenantAdmin.unmapUser.success',
		defaultMessage: 'User unmapped.',
		description: 'Success on unmap user function',
	},
	userUnmapError: {
		id: 'server.tenantAdmin.unmapUser.failure',
		defaultMessage:
			'We were unable to unmap the user.  Please contact customer support.',
		description: 'Error on unmap user function',
	},
	ssoTestSuccess: {
		id: 'server.message.saml.ssoTestSuccess',
		defaultMessage: 'SSO sign in was successful!',
		description: 'Message shown when the SSO sign in test is succesful',
	},
	tenantDeleteSyncInProgress: {
		id: 'server.error.tenant.delete.syncInProgress',
		defaultMessage:
			'There was an error deleting the product instance because a user sync is currently in progress. Please try again later.',
		description:
			'Error message to show if there was an issue deleting a product because a user sync is in progress.',
	},
	tenantMappingError: {
		id: 'server.message.tenant.mapping.error',
		defaultMessage: 'There was an error connecting to the product.',
		description:
			'Error message to show if there was an issue connecting to a product.',
	},
	tenantMappingErrorTimeout: {
		id: 'server.message.tenant.mapping.error.timeout',
		defaultMessage:
			'Connecting the product has timed out. Please try again.',
		description:
			'Error message to show if there is a timeout during the authentication process',
	},
	tenantMappingErrorNotAdminError: {
		id: 'server.message.tenant.mapping.error.notadmin',
		defaultMessage:
			'There was an error connecting with the product. You are not an administrator of this product. Please update your product access to administrator before connecting.',
		description:
			'Error message to show if there was an issue connecting to a product due to the user not logging into the product instance with an admin account.',
	},
	tenantMappingErrorAlreadyExists: {
		id: 'server.message.tenant.mapping.error.exists',
		defaultMessage:
			'There was an error connecting to the product. It has already been connected to Planview Admin.',
		description:
			'Error message to show if there was an issue connecting to a product because it already exists.',
	},
	tenantMappingErrorExistingProdInstance: {
		id: 'server.message.tenant.mapping.error.existing.prod.instance',
		defaultMessage:
			'The product can not be added because there is already another production instance.',
		description:
			'Error message to show if there was an issue adding product because the user tried adding an additional production instance.',
	},
	tenantMappingErrorExistingSandboxInstance: {
		id: 'server.message.tenant.mapping.error.existing.sandbox.instance',
		defaultMessage:
			'The product can not be added because there is already another sandbox instance.',
		description:
			'Error message to show if there was an issue adding a product because the user tried adding an additional sandbox instance.',
	},
	tenantMappingErrorNotSupported: {
		id: 'server.message.tenant.addNotSupported.error',
		defaultMessage: 'Add Tenant for {0} is not supported.',
		description:
			'Error when attempting to add a foundation app with TenantAdminRoutes',
	},
	tenantMappingErrorAppNotEnabled: {
		id: 'server.message.tenant.appNotEnabled.error',
		defaultMessage: 'Application {0} is not enabled.',
		description:
			'Error when attempting to add a tenant and the application is not enabled',
	},
	tenantMappingErrorCancelled: {
		id: 'server.message.tenant.mapping.error.cancelled',
		defaultMessage: 'The request to add the product has been cancelled.',
		description:
			'Error message to show if there was an issue connecting to a product due to the user cancelling the request.',
	},
	tenantUsersSyncQueued: {
		id: 'server.message.sync.queued',
		defaultMessage: 'Sync users job has been queued and will run shortly',
		description:
			'Product instance connected. Users will be imported shortly.',
	},
	tenantUsersSyncFailure: {
		id: 'server.message.sync.cannot.trigger',
		defaultMessage: 'Failed to trigger sync users job',
		description: 'Sync users job cannot be run due to error.',
	},
	tenantUsersSyncTooSoon: {
		id: 'server.message.sync.too.soon',
		defaultMessage: 'Sync users job cannot be run because it ran recently.',
		description: 'Sync users job cannot be run because it ran recently.',
	},
	tenantUsersSyncIsRunning: {
		id: 'server.message.sync.is.running',
		defaultMessage: 'Sync users job is currently running',
		description: 'Sync users job is currently running.',
	},
	tenantUsersLastSyncDetailsError: {
		id: 'server.error.lastSyncDetails',
		defaultMessage: 'Unable to retrieve last sync details',
		description:
			'Error when there is an issue showing the last sync details of a tenant.',
	},
	mapExistingUser: {
		id: 'server.tenantAdmin.mapExistingUser.success',
		defaultMessage: 'User mapped.',
		description: 'Success on map existing user function',
	},
	mapExistingUserError: {
		id: 'server.tenantAdmin.mapExistingUser.failure',
		defaultMessage:
			'We were unable to map the user.  Please contact customer support.',
		description: 'Error on map existing user function',
	},
	mapNewUserPassword: {
		id: 'server.tenantAdmin.mapNewUser.success.password',
		defaultMessage:
			'User mapped. An email has been sent to the user to create a Planview ID account.',
		description:
			'Success on map new user function when user will sign in with password',
	},
	mapNewUserSSO: {
		id: 'server.tenantAdmin.mapNewUser.success.sso',
		defaultMessage:
			'User mapped. An email has been sent to the user confirming their access.',
		description:
			'Success on map new user function when user will sign in with SSO',
	},
	mapNewUserError: {
		id: 'server.tenantAdmin.mapNewUser.failure',
		defaultMessage:
			'We were unable to map the user.  Please contact customer support.',
		description: 'Error on map new user function',
	},
	resetPasswordOrResendInvite: {
		id: 'server.userAdmin.resetPasswordOrResendInvite.success',
		defaultMessage: 'User password reset or invite re-sent.',
		description: 'Success on reset password or resend user invite function',
	},
	resetPasswordOrResendInviteError: {
		id: 'server.userAdmin.resetPasswordOrResendInvite.error',
		defaultMessage:
			'We were unable to resend password reset or invite to the user.  Please contact customer support.',
		description: 'Error on reset password or resend user invite function',
	},
	customerUpdateSuccess: {
		id: 'server.message.customer.update.success',
		defaultMessage: 'The settings have been saved.',
		description: 'Status text to show on successful save of the settings',
	},
	customerUpdateFailure: {
		id: 'server.message.customer.update.failure',
		defaultMessage: 'Unable to update settings.',
		description: 'Status text to show on failure to save the settings',
	},
	customerUpdateUrlError: {
		id: 'server.message.customer.update.error.url',
		defaultMessage:
			'Unable to retrieve IDP metadata from the given URL. Please check the URL and try again. If the issue persists, please contact customer support.',
		description:
			'Error text to show when an invalid URL is provided for IDP metadata',
	},
	customerUpdateXmlViaManualError: {
		id: 'server.message.customer.update.error.xml.manual',
		defaultMessage:
			'The XML you entered does not appear to be IDP metadata. Please correct and try again. If the issue persists, please contact customer support.',
		description:
			'Error text to show when invalid IDP metadata XML is manually entered',
	},
	customerUpdateXmlViaUrlError: {
		id: 'server.message.customer.update.error.xml.url',
		defaultMessage:
			'The XML retrieved via the URL does not appear to be IDP metadata. Please check the URL and try again. If the issue persists, please contact customer support.',
		description:
			'Error text to show when invalid IDP metadata XML is retrieved via the URL',
	},
	customerUpdateNoMetadataError: {
		id: 'server.message.customer.update.error.noMetadata',
		defaultMessage:
			'Single Sign On was enabled, but no metadata was provided. Please correct and try again. If the issue persists, please contact customer support.',
		description:
			'Error text to show when neither IDP metadata XML nor IDP metadata URL is provided by the user',
	},
	forbiddenError: {
		id: 'server.message.forbidden.error',
		description:
			'Error shown on a 403 Forbidden error. Usually caused during CSRF security violation',
		defaultMessage:
			'Security error. Please refresh the page and try again.',
	},
	acceptAccountError: {
		id: 'server.message.acceptAccount.error',
		description:
			'Error shown when something went wrong during the create account process',
		defaultMessage:
			'Oops something went wrong. Please refresh the page and try again, or contact your administrator if the issue persists.',
	},
	invalidHostname: {
		id: 'server.message.addProduct.invalid.hostname',
		description:
			'Error shown when an invalid hostname was found on the Add Product URL. {0} = Product name, {1} = List of valid hostnames',
		defaultMessage:
			'{0} connect URI hostname must end with one of the following: {1}',
	},
	invalidSuffix: {
		id: 'server.message.addProduct.invalid.suffix',
		description:
			'Error shown when an invalid suffix was found on the Add Product URL. {0} = Product name, {1} = List of valid suffixes',
		defaultMessage:
			'{0} connect URI must end with one of the following: {1}',
	},
	expiredToken: {
		id: 'server.message.account.create.expiredToken',
		description:
			'Text shown when the create Planview ID invitation link has expired',
		defaultMessage:
			'This link has expired. Please contact your Planview administrator to request a new link to set up your Planview username and password.',
	},
	multipleTenantsConfigured: {
		id: 'server.message.connections.multipleTenantsConfigured',
		defaultMessage:
			'There are multiple instances of {0}. Connections can only support one production and one primary sandbox instance of each product.',
		description:
			'{0} = application name, e.g. Portfolios. Error text to show when loading the connections page and there is an application added multiple times',
	},
	multiplePPMToolsConfigured: {
		id: 'server.message.connections.multiplePPMToolsConfigured',
		defaultMessage:
			'More than one PPM product (e.g. Portfolios and PPMPro) have been added. Cannot proceed.',
		description:
			'Error text to show when loading the connections page and there are two PPM tools such as Portfolios and PPMPro added multiple times',
	},
	sandboxApplication: {
		id: 'server.sandboxApplicationFormatString',
		defaultMessage: '{0} (Sandbox)',
		description:
			'{0} = application name, e.g. Roadmaps, IdeaPlace. String used to create a tenant title when the application is a sandbox instance',
	},
	adminRole: {
		id: 'server.message.role.admin',
		defaultMessage: 'Administrator',
		description: 'Administrator role name',
	},
	userRole: {
		id: 'server.message.role.user',
		defaultMessage: 'User',
		description: 'User role name',
	},
	invalidEmail: {
		id: 'server.message.invalidEmail',
		defaultMessage: 'Please provide a valid email address.',
		description:
			'Error text to show when the request contains an invalid email address',
	},
	emailAddressIsNotValid: {
		id: 'server.message.emailAddressIsNotValid',
		defaultMessage: '{0} is not a valid email address.',
		description: 'Error text indicating a specific email is not valid',
	},
	firstNameTooLong: {
		id: 'server.message.first.name.too.long',
		defaultMessage: 'First name is too long.',
		description:
			'Error text to show when the request contains a first name that is too long',
	},
	lastNameTooLong: {
		id: 'server.message.last.name.too.long',
		defaultMessage: 'Last name is too long.',
		description:
			'Error text to show when the request contains a last name that is too long',
	},
	loginAliasTooLong: {
		id: 'server.message.loginAlias.too.long',
		defaultMessage: 'Login alias is too long.',
		description:
			'Error text to show when the request contains a login alias that is too long',
	},
	usernameTooLong: {
		id: 'server.message.username.too.long',
		defaultMessage: 'Username is too long.',
		description:
			'Error text to show when the request contains a username that is too long',
	},
	activatedUserDeleteError: {
		id: 'server.userAdmin.delete.activated.user.failure',
		defaultMessage: 'Users must be deactivated before they can be deleted.',
		description: 'Error on delete user if the user is activated',
	},
	addProductEnvironments: {
		id: 'server.message.users.addProductEnvironments',
		defaultMessage:
			'We are provisioning these users for the product environments you selected. You will receive an email notification when this is complete.',
		description:
			'Text shown when the add product environments process begins',
	},
	planviewMeDescription: {
		id: 'server.message.product.planviewMeDescription',
		defaultMessage: 'My Planview Home',
		description:
			'The description (sub-title) used for Planview.Me in the product switcher',
	},
	roadmapsTeams: {
		id: 'server.message.license.roadmapsTeams',
		defaultMessage: 'Teams',
		description: 'Roadmaps teams subscription type name',
	},
	roadmapsEnterprise: {
		id: 'server.message.license.roadmapsEnterprise',
		defaultMessage: 'Enterprise',
		description: 'Roadmaps enterprise subscription type name',
	},
	ideaplaceTeamTap: {
		id: 'server.message.license.ideaplaceTeamTap',
		defaultMessage: 'TeamTap',
		description: 'IdeaPlace TeamTap license type name',
	},
	updateLicenseSuccess: {
		id: 'server.message.license.updateType.success',
		defaultMessage: 'Selected user successfully updated.',
		description:
			'Text shown when a subscription type was updated successfully',
	},
	updateLicensePluralSuccess: {
		id: 'server.message.license.updateType.successPlural',
		defaultMessage: 'Selected users successfully updated.',
		description:
			'Text shown when a subscription type was updated successfully for multiple users',
	},
	licenseNotFound: {
		id: 'server.message.license.notFound',
		defaultMessage: 'Subscription type {0} not found',
		description: 'Subscription type was not found',
	},
	insufficientLicenses: {
		id: 'server.message.license.insufficientLicenses',
		defaultMessage:
			'You do not have {0} subscriptions to assign to the selected user or users. Please contact your CSM to purchase additional subscriptions.',
		description:
			'Error when there are not enough remaining subscriptions to change a subscription type',
	},
	excessUsedLicenses: {
		id: 'server.message.license.excessUsedLicenses',
		defaultMessage:
			'You cannot set the number of subscriptions below {0} until the organization unassigns subscriptions from its users in both production and sandbox environments.',
		description:
			'Error when the specified number of total subscribers is less than the number of used subscriptions',
	},
	duplicateTenantRoleId: {
		id: 'server.message.tenant.duplicateTenantRoleId',
		defaultMessage: 'Duplicate role id detected.',
		description:
			'Error when setting a tenants roles with non unique tenant role IDs',
	},
	deactivateFixedRoleError: {
		id: 'server.userAdmin.deactivate.user.fixedRole.failure',
		defaultMessage: 'Cannot deactivate user {0} as they have a fixed role',
		description:
			'Error when attempting to deactivate a user that has a fixed role',
	},
	deleteFixedRoleError: {
		id: 'server.userAdmin.delete.user.fixedRole.failure',
		defaultMessage: 'Cannot delete user {0} as they have a fixed role',
		description:
			'Error when attempting to delete a user that has a fixed role',
	},
	activateProductError: {
		id: 'server.message.tenant.activation.error',
		defaultMessage: 'Error activating Planview Admin',
		description:
			'Error when attempting to activate a product to use Planview Admin auth',
	},
	deactivateProductError: {
		id: 'server.message.tenant.deactivation.error',
		defaultMessage: 'Error deactivating Planview Admin',
		description:
			'Error when attempting to deactivate a product in Planview Admin to no longer use Planview Admin auth',
	},
});

export default messages;
