import styled from 'styled-components';
import { NonPhoneMinWidth, VBox } from '../common/Layout';
import { color, sizePx, spacingPx } from '@planview/pv-utilities';

export const Section = styled(VBox)`
	flex-basis: auto;
	line-height: ${sizePx.small};
	@media only screen and (min-width: ${NonPhoneMinWidth}px) {
		margin: ${spacingPx.medium};
	}
`;

export const SectionTitle = styled.div`
	background-color: ${color.gray300};
	font-weight: 500;
	line-height: ${sizePx.small};
	padding-left: ${spacingPx.small};
	color: ${color.textPrimary};
`;
