import { defineMessages } from 'react-intl';

const messages = defineMessages({
	orgNameColumn: {
		id: 'pvadmin.org.column.orgName',
		description: 'Label for Organization Name column for Organization Grid',
		defaultMessage: 'Organization name',
	},
	orgTagsColumn: {
		id: 'pvadmin.org.column.orgTags',
		description: 'Label for org tags column for Organization Grid',
		defaultMessage: 'Organization tags',
	},
	idColumn: {
		id: 'pvadmin.org.column.id',
		description: 'Label for ID column for Organization Grid',
		defaultMessage: 'ID',
	},
	parentTitle: {
		id: 'pvadmin.org.column.parentTitle',
		description: 'Label for org parent column for Organization Grid',
		defaultMessage: 'Parent organization',
	},
	uofpBranchCode: {
		id: 'pvadmin.org.column.uofpBranchCode',
		description: 'Label for University of Planview Branch Code',
		defaultMessage: 'SFDC ID number / UP branch code',
	},
	regulatoryRegionColumn: {
		id: 'pvadmin.org.column.regulatoryRegion',
		description: 'Label for Regulatory Region column for Organization Grid',
		defaultMessage: 'Regulatory region',
	},
	scheduledDeletionTime: {
		id: 'pvadmin.org.column.scheduledDeletionTime',
		description: 'Label for Delete after date column for Organization Grid',
		defaultMessage: 'Delete after',
	},
	addOrg: {
		id: 'pvadmin.org.button.addOrg',
		description: 'Add Organization button text',
		defaultMessage: 'Add organization',
	},
	TDUMenabled: {
		id: 'pvadmin.org.column.TDUMenabled',
		description:
			'Label for Top Down User Management column for Organization Grid',
		defaultMessage: 'TDUM enabled',
	},
	TDUMHeaderToolTip: {
		id: 'pvadmin.org.column.TDUMtooltip',
		description: 'Tool tip text for actions icon in Users grid',
		defaultMessage: 'Top-Down User Management',
	},
	resendMessagesForOrgTenants: {
		id: 'pvadmin.org.grid.menu.resendMessagesForOrgTenants',
		description:
			'Resend messages for all organization tenants menu item text',
		defaultMessage: 'Resend messages to all tenants',
	},
	actionsToolTip: {
		id: 'pvadmin.org.grid.actionsMenu.tooltip',
		description: 'Tool tip text for actions icon in Organization grid',
		defaultMessage: 'Actions',
	},
	deleteOrgSuccess: {
		id: 'pvadmin.org.delete.success',
		description:
			'Text of success / non failure response to delete organization',
		defaultMessage: 'The organization is scheduled for deletion.',
	},
	deleteParentOrgSuccess: {
		id: 'pvadmin.org.deleteParentOrg.success',
		description:
			'Text of success / non failure response to delete a parent organization',
		defaultMessage: 'The parent organization had been deleted.',
	},
	restoreOrgSuccess: {
		id: 'pvadmin.org.restore.success',
		description:
			'Text of success / non failure response to restore organization',
		defaultMessage: 'The organization is no longer scheduled for deletion.',
	},
	resendMessagesForOrgTenantsSuccess: {
		id: 'pvadmin.org.resendMessagesForOrgTenants.success',
		description:
			'Text of success / non failure response to resend messages for all organization tenants',
		defaultMessage:
			'Messages have been resent to all tenants of the organization.',
	},
});

export default messages;
