import { defineMessages } from 'react-intl';

const messages = defineMessages({
	invalidEmailFormat: {
		id: 'validation.input.email.invalidFormat',
		description: 'Error tooltip message for an invalid email input field',
		defaultMessage: 'Invalid email format',
	},
	invalidEmail: {
		id: 'validation.input.email.invalid',
		description: 'Error tooltip message for an invalid email input field',
		defaultMessage: 'Invalid email address',
	},
	passwordLength: {
		id: 'validation.input.password.length',
		description:
			'Error tooltip message for password input field that does not have minimum length requirements',
		defaultMessage: 'Password must be at least 12 characters',
	},
	invalidUrl: {
		id: 'validation.input.url.invalid',
		description:
			'Error tooltip message for an invalid URL inside an input field',
		defaultMessage: 'Please enter a valid URL',
	},
	requiredEmail: {
		id: 'validation.input.email.required',
		description: 'Error tooltip message for an blank email input field',
		defaultMessage: 'Required Email',
	},
	requiredPassword: {
		id: 'validation.input.password.required',
		description: 'Error tooltip message for an blank password input field',
		defaultMessage: 'Required Password',
	},
	enterFirstName: {
		id: 'validation.input.field.enterFirstName',
		description:
			'Error tooltip message for an blank first name input field',
		defaultMessage: 'Please enter a first name',
	},
	enterLastName: {
		id: 'validation.input.field.enterLastName',
		description: 'Error tooltip message for an blank last name input field',
		defaultMessage: 'Please enter a last name',
	},
	enterEmail: {
		id: 'validation.input.field.enterEmail',
		description: 'Error tooltip message for an blank email input field',
		defaultMessage: 'Please enter an email address',
	},
	requiredField: {
		id: 'validation.input.field.required',
		description: 'Error tooltip message for an blank required input field',
		defaultMessage: 'Required field',
	},
});

export default messages;
