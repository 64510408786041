/**
 * The ProductsPage is comprised of a section of tiles (for users and admin) that have
 * configuration options exposed, they are not actionable (link to launch the tenant).
 *
 * Users have the ability to update the tile description.
 *
 * Customer Admins can: edit the default description, sync tenant users, and go to the tenant ProductDetailsPage
 * if they have a user account with that tenant.  If no username, then the tile is read-only (informational).
 * Customer Admins also have a section of "Add Product" where available product lines (PPMP, E1, LK, PP, IdeaPlace...)
 * can be connected to the admins customer.
 */

import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage, MessageDescriptor, useIntl } from 'react-intl';
import styled from 'styled-components';
import { spacingPx } from '@planview/pv-utilities';
import ProductTile from '../../../components/home/productTile/ProductTile';
import {
	AppContext,
	CustomerContext,
	ProductTileContext,
} from '../../../context';
import { HBox, VBox } from '../../../components/common/Layout';
import { Section } from '../../../components/home/Section';
import { PageHeader } from '../../../components/common/Wrappers';
import messages from './ProductsPage.messages';
import { requestWithErrorHandling } from '../../../hooks/request/request';
import { Application, Customer } from '../../../types';
import { CustomerDto } from '../../../types/api/customers';
import { AvailableApplication } from '../../../context/appContext';
import { defaultCustomerState } from '../../../context/customerContext';
import { TenantsByEnvSelector } from '../../../types/api/tenants';
import { getTenantsByEnvSelector } from '../../../helpers/util';
import { isApplication } from '../../../types/typeguard';

const Container = styled.div`
	height: 100%;
	overflow: auto;
`;

const MessageDisplay = styled.div`
	padding-top: ${spacingPx.medium};
	padding-left: ${spacingPx.medium};
`;

type ProductsSectionProps = {
	applications: Application[] | AvailableApplication[];
	tileType: 'mapped' | 'owned';
	title: MessageDescriptor;
	noProductsDisplayMessage: MessageDescriptor;
};

const ProductsSection = (props: ProductsSectionProps) => {
	const { applications, tileType, title, noProductsDisplayMessage } = props;
	return (
		<Section data-testid={'products-section'}>
			<PageHeader>
				<FormattedMessage {...title} />
			</PageHeader>
			<HBox>
				{applications.map((application, idx) =>
					// Don't display custom products on Products Page
					isApplication(application) && application.custom ? null : (
						<ProductTile
							tileType={tileType}
							editMode
							key={idx}
							application={application}
						/>
					),
				)}
				{applications.length < 1 ? (
					<MessageDisplay>
						<FormattedMessage {...noProductsDisplayMessage} />
					</MessageDisplay>
				) : null}
			</HBox>
		</Section>
	);
};

function ProductsPage() {
	const appContext = useContext(AppContext);
	const intl = useIntl();
	const { applications = [], availableApplications = [] } = appContext;
	const [customer, setCustomer] = useState<Customer>(defaultCustomerState);
	const [tenantsByEnvSelector, setTenantsByEnvSelector] =
		useState<TenantsByEnvSelector>({});

	const hasIdeaplaceMapped = applications.some(
		(application) => application.app === 'TEAMTAP',
	);

	useEffect(() => {
		const getCustomerDetails = async () => {
			const customerResult = await requestWithErrorHandling<CustomerDto>({
				method: 'get',
				url: `/io/v1/customer`,
				intl,
				appContext,
			});
			setCustomer(customerResult);
			const tenantsByEnvSelector = getTenantsByEnvSelector(
				customerResult.tenants || [],
			);
			setTenantsByEnvSelector(tenantsByEnvSelector);
		};
		void getCustomerDetails();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<CustomerContext.Provider value={{ customer, tenantsByEnvSelector }}>
			<Container>
				<VBox flex={4}>
					<ProductTileContext.Provider
						value={{ enableHiddenIndicator: true }}
					>
						<ProductsSection
							tileType="mapped"
							applications={applications}
							title={messages.pageTitle}
							noProductsDisplayMessage={messages.noProducts}
						/>
					</ProductTileContext.Provider>
					<ProductsSection
						tileType="owned"
						applications={
							hasIdeaplaceMapped
								? availableApplications.filter(
										(application) =>
											application.app !== 'TEAMTAP',
									)
								: availableApplications
						}
						title={messages.addProductTitle}
						noProductsDisplayMessage={messages.noAvailableProducts}
					/>
				</VBox>
			</Container>
		</CustomerContext.Provider>
	);
}

export default ProductsPage;
