import React, { useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import { AppContext } from '../../../context';
import { requestWithErrorHandling } from '../../../hooks/request/request';
import messages from './NewOrganizationDialog.messages';
import { default as formMessages } from '../../../helpers/validation.messages';
import { Customer } from '../../../types';
import { UpdateCustomerDto } from '../../../types/api/customers';
import { ApiResponse } from '../../../types/api/api';
import { Modal } from '../../../components/common/modal/Modal';
import { preventEventBubbling } from '../../common/PreventEventBubbling';
import { Form, Input } from '@planview/pv-form';
import { SectionHeader } from '../../../components/common/Dialog';
import { useForm } from '@mantine/form';

type EditParentOrganizationDialogProps = {
	onConfirm: () => void;
	onCancel: () => void;
	organization: Customer;
};

const EditParentOrganizationDialog = (
	props: EditParentOrganizationDialogProps,
) => {
	const { onConfirm, onCancel, organization } = props;
	const { id } = organization;
	const appContext = useContext(AppContext);
	const { enableUpBranchCode } = appContext.featureFlags;
	const intl = useIntl();

	const [isSaving, setSaving] = useState(false);

	const orgForm = useForm({
		initialValues: {
			title: organization.title,
			uofpBranchCode: organization.uofpBranchCode,
			orgTags: organization.orgTags,
		},
		validateInputOnBlur: true,
		validate: {
			title: (val) =>
				!val ? intl.formatMessage(formMessages.requiredField) : null,
		},
	});

	const { title, uofpBranchCode } = orgForm.values;

	const sendUpdateOrgRequest = async () => {
		const updateCustomerDto: UpdateCustomerDto = {
			title,
			uofpBranchCode: uofpBranchCode,
		};

		const { success } = await requestWithErrorHandling<ApiResponse>({
			method: 'put',
			url: `/io/v1/admin/customer/${id}`,
			dataObj: updateCustomerDto,
			appContext: appContext,
			intl,
			successMessage: messages.editOrgSuccess,
		});

		return success;
	};

	const save = async () => {
		setSaving(true);
		const success = await sendUpdateOrgRequest();
		setSaving(false);
		if (success && onConfirm) {
			onConfirm();
		}
	};

	return (
		<Modal
			id="edit-organization-dialog"
			onConfirm={save}
			onCancel={onCancel}
			confirmText={intl.formatMessage(messages.editButton)}
			cancelText={intl.formatMessage(messages.cancelButton)}
			headerText="Edit parent organization"
			disableConfirm={isSaving || !orgForm.isValid()}
			{...preventEventBubbling}
		>
			<Form label="Edit organization form">
				<SectionHeader>
					<Input
						label={intl.formatMessage(messages.organizationTitle)}
						id={'organization-name'}
						withAsterisk={true}
						{...orgForm.getInputProps('title')}
					/>
				</SectionHeader>

				{enableUpBranchCode && (
					<SectionHeader>
						<Input
							label={intl.formatMessage(messages.branchCodeLabel)}
							id={'uofp-branch-code'}
							maxLength={50}
							{...orgForm.getInputProps('uofpBranchCode')}
						/>
					</SectionHeader>
				)}
			</Form>
		</Modal>
	);
};

export default EditParentOrganizationDialog;
