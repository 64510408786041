/* eslint-disable react/display-name */
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
	borderRadius,
	borderStyle,
	color,
	size,
	sizePx,
	spacingPx,
} from '@planview/pv-utilities';
import AppLogo from '../../../components/common/appLogo/AppLogo';
import { HBox } from '../../../components/common/Layout';
import { ButtonPrimary } from '../../../components/common/button/Button';
import { requestWithErrorHandling } from '../../../hooks/request/request';
import { AppContext } from '../../../context';
import messages from './ConnectionsPage.messages';
import { useIntl } from 'react-intl';
import { Body, Modal } from '../../../components/common/modal/Modal';
import { MODAL_SMALL } from '@planview/pv-uikit';
import ProgressIcon from '../../../assets/loading.gif';

export const Tile = styled.div`
	margin: ${spacingPx.small} 0 ${spacingPx.xsmall} 0;
	padding: 4px 16px 6px 16px;
	${borderStyle.solid}
	${borderRadius.rounded(0.25 * size.xsmall)};
	border-color: #d8d8d8;
	background-color: #fff;
`;

const StyledHBox = styled(HBox)`
	align-items: center;
`;
const StatusDiv = styled.div`
	width: 32px;
`;
const StatusIndicator = styled.div`
	width: calc(${sizePx.xxsmall} + 4px);
	height: ${sizePx.xsmall};
	${borderRadius.rounded(size.xsmall)};
	color: white;
	line-height: ${sizePx.xsmall};
	font-size: 12px;
	padding: 0 3px;
	cursor: default;
`;
const ConnectingIndicator = styled.div`
	width: ${sizePx.xsmall};
	height: ${sizePx.xsmall};
	line-height: ${sizePx.xsmall};
	background: url(${ProgressIcon}) no-repeat center center;
`;
const ConnectedIndicator = styled(StatusIndicator)`
	background-color: ${color.primary500};
`;
const ErrorIndicator = styled(StatusIndicator)`
	border-radius: 0;
	color: #d56261;
	font-size: ${sizePx.xsmall};
	padding: 0;
	width: ${sizePx.xsmall};
`;
const LogoDiv = styled.div`
	width: 180px;
`;
const DetailsSection = styled.div`
	margin: 0 ${spacingPx.medium} 0 ${spacingPx.medium};
	width: 220px;
`;
const Description = styled.div`
	font-size: 13px;
	color: ${color.textPlaceholder};
`;
const UserDescription = styled(Description)`
	color: ${color.textPrimary};
`;
const ConnectButton = styled(ButtonPrimary)`
	padding: 0 ${spacingPx.small};
	margin-left: ${spacingPx.small};
	background-color: ${color.primary500};
`;
const ConfirmConnectItem = styled.div`
	margin-left: ${spacingPx.medium};
`;

const ConfirmationDialog = ({
	onConfirm,
	onCancel,
	disableConfirm,
	parent,
	child,
	confirmMessageString,
	confirmText,
	headerText,
}) => {
	const { formatMessage } = useIntl();

	const parentName = parent.productName;
	const childName = child.tenant.productName;

	const confirmMessage = formatMessage(confirmMessageString, {
		product1: parentName,
		product2: childName,
		product1Description: parent.title,
		p: (...chunks) => <p>{chunks}</p>,
		item: (...chunks) => <ConfirmConnectItem>{chunks}</ConfirmConnectItem>,
	});

	return (
		<Modal
			size={MODAL_SMALL}
			onConfirm={onConfirm}
			onCancel={onCancel}
			confirmText={formatMessage(confirmText)}
			headerText={formatMessage(headerText)}
			disableConfirm={disableConfirm}
		>
			<Body>{confirmMessage}</Body>
		</Modal>
	);
};

const ChildConnection = (props) => {
	const appContext = useContext(AppContext);
	const intl = useIntl();
	const { formatMessage } = intl;
	const { parent, child } = props;
	const { title, domain, envSelector } = child.tenant;
	const application = envSelector.application;
	const allowConnect = child.allowConnect;
	const [isConnecting, setIsConnecting] = useState(false);
	const [showConfirmConnect, setShowConfirmConnect] = useState(false);
	const allowDisconnect = child.allowDisconnect;
	const [isDisconnecting, setIsDisconnecting] = useState(false);
	const [showConfirmDisconnect, setShowConfirmDisconnect] = useState(false);
	const status = child.status;

	useEffect(() => {
		// these two flags are only needed until temporarily during connect/disconnect until status is updated from server
		setIsConnecting(false);
		setIsDisconnecting(false);
	}, [status]);

	const doConnect = async () => {
		await requestWithErrorHandling({
			method: 'PUT',
			url: '/api/connections',
			dataObj: {
				parent: parent.envSelector,
				child: envSelector,
			},
			appContext,
			intl,
		});
	};

	const doDisconnect = async () => {
		const parentEnvSelector = encodeURIComponent(
			`${parent.envSelector.application}~${parent.envSelector.tenantId}`,
		);
		const childEnvSelector = encodeURIComponent(
			`${envSelector.application}~${envSelector.tenantId}`,
		);

		await requestWithErrorHandling({
			method: 'DELETE',
			url: `/api/connections/${parentEnvSelector}/${childEnvSelector}`,
			appContext,
			intl,
		});
	};

	const getErrorMessage = () => {
		// note, these error messages aren't globalized anyways
		// eslint-disable-next-line prefer-template
		return formatMessage(messages.errorHoverText) + '\n' + child.message;
	};

	let effectiveStatus = status;
	if (isConnecting) {
		effectiveStatus = 'TO_BE_PROVISIONED';
	} else if (isDisconnecting) {
		effectiveStatus = 'TO_BE_DEPROVISIONED';
	}

	return (
		<>
			<Tile>
				<StyledHBox>
					<StatusDiv>
						{effectiveStatus === 'TO_BE_PROVISIONED' ? (
							<ConnectingIndicator
								title={formatMessage(
									messages.connectingHoverText,
								)}
							></ConnectingIndicator>
						) : null}
						{effectiveStatus === 'TO_BE_DEPROVISIONED' ? (
							<ConnectingIndicator
								title={formatMessage(
									messages.disconnectingHoverText,
								)}
							></ConnectingIndicator>
						) : null}
						{effectiveStatus === 'CONNECTED' ? (
							<ConnectedIndicator
								title={formatMessage(
									messages.connectedHoverText,
								)}
							>
								✓
							</ConnectedIndicator>
						) : null}
						{effectiveStatus === 'ERROR' ? (
							<ErrorIndicator title={getErrorMessage()}>
								⚠
							</ErrorIndicator>
						) : null}
					</StatusDiv>
					<LogoDiv>
						<AppLogo app={application} />
					</LogoDiv>
					<DetailsSection>
						<UserDescription>{title}</UserDescription>
						<Description>{domain}</Description>
					</DetailsSection>
					{allowConnect && !isConnecting ? (
						<ConnectButton
							message={messages.connectButton}
							onClick={() => {
								setShowConfirmConnect(true);
							}}
						/>
					) : null}
					{allowDisconnect && !isDisconnecting ? (
						<ConnectButton
							message={messages.disconnectButton}
							onClick={() => {
								setShowConfirmDisconnect(true);
							}}
						/>
					) : null}
				</StyledHBox>
			</Tile>
			{showConfirmConnect ? (
				<ConfirmationDialog
					parent={parent}
					child={child}
					disableConfirm={isConnecting}
					confirmMessageString={messages.confirmConnectMessage}
					confirmText={messages.connectButton}
					headerText={messages.confirmConnectHeader}
					onCancel={() => {
						setShowConfirmConnect(false);
					}}
					onConfirm={async () => {
						setIsConnecting(true);
						await doConnect();
						setShowConfirmConnect(false);
					}}
				/>
			) : null}
			{showConfirmDisconnect ? (
				<ConfirmationDialog
					parent={parent}
					child={child}
					disableConfirm={isDisconnecting}
					confirmMessageString={messages.confirmDisconnectMessage}
					confirmText={messages.disconnectButton}
					headerText={messages.confirmDisconnectHeader}
					onCancel={() => {
						setShowConfirmDisconnect(false);
					}}
					onConfirm={async () => {
						setIsDisconnecting(true);
						await doDisconnect();
						setShowConfirmDisconnect(false);
					}}
				/>
			) : null}
		</>
	);
};

export default ChildConnection;
