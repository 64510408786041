import React, { useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import { Modal, MODAL_MEDIUM } from '@planview/pv-uikit';
import { get, post } from '../../../hooks/request/request';
import { AppContext, CustomerContext } from '../../../context';
import { ShowToastFn } from '../../../context/appContext';
import messages from './NewUserDialog.messages';
import { UserDetailsForm, useUserDetailsForm } from './UserDetailsForm';
import { AssignProductsSection } from './AssignProductsSection';
import { Customer } from '../../../types';
import {
	UsersByEmailOrIdResponseDto,
	UserUpsertDto,
	UserUpsertResponse,
} from '../../../types/api/users';
import AddToBusinessUnitModal from './AddToBusinessUnitModal';
import { ToastType } from '../../../types/toast';
import { isAdaptiveWorkCustomer, isMissingRoles } from '../../../helpers/util';

const initialUserDto: UserUpsertDto = {
	id: '',
	email: '',
	firstName: '',
	lastName: '',
	userTenantAssignments: [],
	isAdmin: false,
	localAuthOverrideEnabled: false,
};

type NewUserDialogProps = {
	customer: Customer;
	onConfirm: () => void;
	onCancel: () => void;
	isCustomerCare: boolean;
};

const NewUserDialog = (props: NewUserDialogProps) => {
	const { customer, onConfirm, onCancel, isCustomerCare } = props;
	const appContext = useContext(AppContext);
	const customerContext = useContext(CustomerContext);
	const intl = useIntl();
	const showLocalAuthOverride = customer.ssoEnabled;
	const showLoginAlias = customer.samlNameIdLookupType === 'LOGIN_ALIAS';
	const [user, setUser] = useState(initialUserDto);
	const [isSaving, setIsSaving] = useState(false);
	const [showAddToBusinessUnitModal, setShowAddToBusinessUnitModal] =
		useState(false);
	const orgHierarchyEnabled = isCustomerCare
		? !!customerContext.customer?.orgHierarchyEnabled
		: customer.orgHierarchyEnabled;
	const parentCustomerId = isCustomerCare
		? customerContext.customer?.parentCustomerId
		: customer.parentCustomerId;
	const isAWCustomer = isAdaptiveWorkCustomer(customer);
	const singleUserForm = useUserDetailsForm(user, intl);
	const fireRequest = async (showToast: ShowToastFn) => {
		const sendUserUpsertRequest = async () => {
			const userUpsertDto: UserUpsertDto = {
				...user,
				...singleUserForm.values,
			};

			const url = isCustomerCare
				? `/io/v1/admin/user?customerId=${customer.id}`
				: `/io/v1/user`;
			const { success, message } = (await post(
				url,
				userUpsertDto,
				false,
			)) as UserUpsertResponse;

			showToast({
				message,
				type: success ? ToastType.SUCCESS : ToastType.DANGER,
			});

			return success;
		};

		return await sendUserUpsertRequest();
	};

	const save = async ({ showToast }: { showToast: ShowToastFn }) => {
		const validationResult = singleUserForm.validate();
		if (validationResult.hasErrors || isSaving) {
			return;
		}

		setIsSaving(true);

		const success = await fireRequest(showToast);
		if (success && onConfirm) {
			onConfirm();
		} else {
			setIsSaving(false);
		}
	};

	const sendByEmailsRequest = async (emails: string[]) => {
		const urlParams = emails.map((email) => ['emails', email]);
		let route;
		const queryString = new URLSearchParams(urlParams);
		if (isCustomerCare) {
			route = '/io/v1/admin/user/byEmails';
			if (parentCustomerId) {
				queryString.append('parentCustomerId', parentCustomerId);
			}
		} else {
			route = '/io/v1/user/byEmails';
		}

		const usersByEmailResponse = (await get(
			`${route}?${queryString.toString()}`,
		)) as UsersByEmailOrIdResponseDto;
		if (usersByEmailResponse.users?.length) {
			setShowAddToBusinessUnitModal(true);
		} else {
			void save(appContext);
		}
	};

	const checkExistingUser = async () => {
		// Check if user already exists in another business unit
		await sendByEmailsRequest([singleUserForm.values.email as string]);
	};

	const missingRoles = isMissingRoles(user.userTenantAssignments);

	const isFormValid = !Object.entries(singleUserForm.errors).length;

	return (
		<>
			<Modal
				id="new-user-modal"
				size={MODAL_MEDIUM}
				onConfirm={() =>
					orgHierarchyEnabled ? checkExistingUser() : save(appContext)
				}
				onCancel={onCancel}
				cancelText={intl.formatMessage(messages.cancelButton)}
				confirmText={intl.formatMessage(messages.addButton)}
				headerText={intl.formatMessage(messages.addUserHeader)}
				// Using form.errors over form.isValid() here so that users can see form errors
				disableConfirm={isSaving || !isFormValid || missingRoles}
				initialFocusId="firstName"
			>
				<UserDetailsForm
					form={singleUserForm}
					showLocalAuthOverride={showLocalAuthOverride}
					showLoginAlias={showLoginAlias}
					isAWCustomer={isAWCustomer}
				/>
				<AssignProductsSection
					user={user}
					setUser={setUser}
					isCustomerCare={isCustomerCare}
					noDefaultOptions={false}
				/>
			</Modal>
			{showAddToBusinessUnitModal ? (
				<AddToBusinessUnitModal
					headerText={intl.formatMessage(
						messages.addToBusinessUnitHeader,
					)}
					confirmText={intl.formatMessage(
						messages.addToBusinessUnitConfirm,
					)}
					onConfirm={async () => {
						await save(appContext);
						setShowAddToBusinessUnitModal(false);
					}}
					onCancel={() => setShowAddToBusinessUnitModal(false)}
				/>
			) : null}
		</>
	);
};

NewUserDialog.displayName = 'NewUserDialog';
export default NewUserDialog;
