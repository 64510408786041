/* eslint-disable no-nested-ternary */
import styled from 'styled-components';
import { spacingPx } from '@planview/pv-utilities';

export const PageHeader = styled.div`
	font-size: 24px;
	padding: ${spacingPx.small} 0 0 0;
`;
PageHeader.displayName = 'PageHeader';

type FilterContainerProps = {
	filterVisible?: boolean;
};

export const FilterContainer = styled.div`
	height: 100%;
	width: 280px;
	flex-shrink: 0;
	transform: ${(props: FilterContainerProps) =>
		props.filterVisible ? 'translateX(0)' : 'translateX(-280px)'};
	transition: transform ease-in 0.25s;
	display: flex;
	flex-direction: row;
`;
FilterContainer.displayName = 'FilterContainer';

type MainContainerProps = {
	filterVisible?: boolean;
	detailsPaneVisible?: boolean;
};
export const MainContainer = styled.div`
	height: 100%;
	background: white;
	max-width: calc(
		100% -
			${(props: MainContainerProps) =>
				props.filterVisible
					? props.detailsPaneVisible
						? '280px - 460px'
						: '280px'
					: props.detailsPaneVisible
						? '460px'
						: '0px'}
	);
	min-width: calc(
		100% -
			${(props) =>
				props.filterVisible
					? props.detailsPaneVisible
						? '280px - 460px'
						: '280px'
					: props.detailsPaneVisible
						? '460px'
						: '0px'}
	);
	transform: ${(props) =>
		props.filterVisible ? 'translateX(0)' : 'translateX(-280px)'};
	transition: all ease-in 0.25s;
`;
MainContainer.displayName = 'MainContainer';

type ListWrapperProps = {
	activeFilters?: boolean;
};
export const ListWrapper = styled.div`
	height: ${(props: ListWrapperProps) =>
		props.activeFilters ? `calc(100% - ${spacingPx.large})` : '100%'};
`;
