/* eslint-disable max-statements */

import useEditLicenseTypeAction from './useEditLicenseTypeAction';
import useEditRoleAction from './useEditRoleAction';
import { AppContext } from '../../../../context';
import { useContext } from 'react';
import {
	isAppIdeaplaceEnabled,
	isAppRoadmapsEnabled,
} from '../../../../helpers/util';
import useRemoveUserActions from './useRemoveUserActions';

// React hook for user actions
const useProductActions = (refresh, application, setLicenseDetails) => {
	const appContext = useContext(AppContext);
	const { enableRoadmapsLicensing, enableIdeaplaceLicensing } =
		appContext.featureFlags;
	const showLicenseOption =
		(isAppRoadmapsEnabled(application.app, !!enableRoadmapsLicensing) ||
			isAppIdeaplaceEnabled(
				application.app,
				!!enableIdeaplaceLicensing,
			)) &&
		application.licenseTypes?.length;

	const { editLicenseTypeAction, editLicenseTypeModal } =
		useEditLicenseTypeAction({ application, refresh, setLicenseDetails });

	const { editRoleAction, editRoleModal } = useEditRoleAction({
		application,
		refresh,
	});

	const { removeUserAction, removeUsersAction, removeUserModal } =
		useRemoveUserActions({
			application,
			refresh,
		});

	const menuActions = [removeUserAction];
	let toolbarActions = [];

	if (showLicenseOption) {
		toolbarActions.push(editLicenseTypeAction);
	}

	const showEditRoleAction = application.roles?.length;
	if (showEditRoleAction) {
		toolbarActions.push(editRoleAction);
	}

	toolbarActions.push(removeUserAction);
	toolbarActions.push(removeUsersAction);

	const modals = [editLicenseTypeModal, editRoleModal, removeUserModal];
	return { menuActions, toolbarActions, modals };
};

export default useProductActions;
