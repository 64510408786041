import React, { useState } from 'react';
import { Tenant } from '../../../types';
import { DotFilled, WarningFilled } from '@planview/pv-icons';
import { color } from '@planview/pv-utilities';
import { ButtonEmpty, RightTooltip } from '@planview/pv-uikit';
import messages from './ProductTile.messages';
import { IntlShape, useIntl } from 'react-intl';
import { ErrorMessageModal } from './TileErrorMessage';

const PvAdminActiveDot = (intl: IntlShape) => {
	return (
		<div>
			<RightTooltip
				text={intl.formatMessage(messages.planviewAdminEnabled)}
			>
				<DotFilled color={color.success500} />
			</RightTooltip>
		</div>
	);
};

const PvAdminInactiveDot = (intl: IntlShape) => {
	return (
		<div>
			<RightTooltip
				text={intl.formatMessage(messages.planviewAdminDisabled)}
			>
				<DotFilled color={color.gray400} />
			</RightTooltip>
		</div>
	);
};

const SyncInProgressDot = (intl: IntlShape) => {
	return (
		<div>
			<RightTooltip text={intl.formatMessage(messages.syncInProgress)}>
				<DotFilled color={color.info500} />
			</RightTooltip>
		</div>
	);
};

const SyncErrorsIcon = (
	intl: IntlShape,
	pvAdminRegistered: boolean,
	tenant: Tenant,
) => {
	const [showModal, setShowModal] = useState(false);

	const tooltip = pvAdminRegistered
		? intl.formatMessage(messages.pvAdminEnabledSyncErrorsTooltip)
		: intl.formatMessage(messages.pvAdminDisabledSyncErrorsTooltip);

	return (
		<div>
			<RightTooltip text={tooltip}>
				<ButtonEmpty
					icon={<WarningFilled color={color.error500} />}
					onClick={() => setShowModal(true)}
				/>
			</RightTooltip>
			{showModal && (
				<ErrorMessageModal tenant={tenant} onToggle={setShowModal} />
			)}
		</div>
	);
};

type TileIndicatorProps = {
	tenant: Tenant | undefined;
};
/**
 *  Indicator for product states on tiles
 *
 *  Key:
 *  Green: PV Admin auth enabled tenant.ssoEnabled
 * 	Grey: Registered but PV Admin auth disabled: !tenant.ssoEnabled
 * 	Blue: Sync in progress: tenant.syncStart !== null
 * 	Red: Failed to sync/ sync errors: tenant.lastSyncErrorMessage
 *
 * @param tenant
 * @constructor
 */
const TileIndicator = ({ tenant }: TileIndicatorProps) => {
	const intl = useIntl();
	const syncInProgress = tenant?.syncStart;
	const syncErrors = tenant?.lastSyncErrorMessage;
	const pvAdminRegistered = tenant?.ssoEnabled;

	if (tenant) {
		if (syncInProgress) {
			return SyncInProgressDot(intl);
		} else if (syncErrors) {
			return SyncErrorsIcon(intl, !!pvAdminRegistered, tenant);
		} else if (pvAdminRegistered) {
			return PvAdminActiveDot(intl);
		} else {
			return PvAdminInactiveDot(intl);
		}
	}
};

export default TileIndicator;
