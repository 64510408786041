import { Link as BaseLink, NavLink as BaseNavLink } from 'react-router-dom';
import styled from 'styled-components';
import { color, sizePx, spacingPx } from '@planview/pv-utilities';

export const Link = styled(BaseLink)`
	color: #21213d;
	display: block;
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}
`;

export const NavLink = styled(BaseNavLink)`
	color: ${color.textInverse};
	line-height: calc(${sizePx.medium} - 9px);
	padding-top: calc(${spacingPx.xsmall} - 1px);
	padding-left: ${spacingPx.small};
	padding-right: ${spacingPx.small};
	text-decoration: none;
	border-bottom: 4px solid transparent;

	&.active {
		border-bottom: solid 4px ${color.primary400};

		&:hover {
			border-bottom: solid 4px ${color.primary400};
		}
	}

	&:hover {
		border-bottom: solid 4px ${color.primary200};
	}
`;
